import { IRecord, IWordsData } from '@/components/records';
import { UNKNOWN_SPEAKER } from '@/components/dictors/const';
import { IDictor } from '@/types/dictors';
import { gAPP_STORE } from '@/app/app-store';
import { MAX_DICTOR_NAME_LENGTH_CHIP } from '@/common/constants';

import i18n from '@/i18n';

const dictorTitle = (name: string, surname?: string) => {
  return surname ? `${name} ${surname}` : `${name}`;
};

export const dictorTitleForDto = (index: number, name: string, surname?: string) => {
  const isDefaultSpeaker = name.split(' ')[0] === '#Speaker#';

  return dictorTitle(isDefaultSpeaker ? `${i18n.t(UNKNOWN_SPEAKER)} ${index}` : name, surname);
};

const isDefault = (dictor: IDictor) =>
  dictor.defaultName === undefined ? dictor.name.split(' ')[0] === '#Speaker#' : dictor.defaultName;

export const dictorTitleWithDefaultName = (dictor: IDictor, index: number, defaultName: string) =>
  isDefault(dictor) ? dictorTitle(defaultName, `${index}`) : dictorTitle(dictor.name, dictor.surname);

export const getTitle = (dictor: IDictor, report?: boolean): string => {
  const dictorsStore = gAPP_STORE.getDictorsStore();
  const { dictorNumByRecordId } = dictorsStore;

  const unknownSpeakerName = report
    ? gAPP_STORE.unknownDictorInReport ?? i18n.t(UNKNOWN_SPEAKER)
    : i18n.t(UNKNOWN_SPEAKER);

  if (dictor.defaultName && !dictor.disabledForEditing) {
    return `${unknownSpeakerName} ${dictorNumByRecordId(dictor)} ${dictor.surname ?? ''}`.trim();
  } else if (dictor.disabledForEditing) {
    return `${unknownSpeakerName} ${dictorNumByRecordId(dictor)}`.trim();
  }

  return dictor.surname ? `${dictor.name} ${dictor.surname}`.trim() : dictor.name;
};

export const getShortTitleFromIDictor = (dictor: IDictor): string => {
  const dictorName = getTitle(dictor);

  return getShortTitleFromString(dictorName);
};

export const getShortTitleFromString = (dictorName: string, maxLength?: number): string => {
  const maxDictorNameLength = maxLength ?? MAX_DICTOR_NAME_LENGTH_CHIP;
  if (dictorName.length > maxDictorNameLength) {
    dictorName = `${dictorName.slice(0, maxDictorNameLength)}…`;
  }

  return dictorName;
};

export const isRTLLocal = (text: string) => {
  const rtl_count = (text.match(/[\u0591-\u07FF\uFB1D-\uFDFD\uFE70-\uFEFC]/g) || []).length;
  const ltr_count = (
    text.match(
      /[A-Za-z\u00C0-\u00C0\u00D8-\u00F6\u00F8-\u02B8\u0300-\u0590\u0800-\u1FFF\u2C00-\uFB1C\uFDFE-\uFE6F\uFEFD-\uFFFF]/g,
    ) || []
  ).length;

  return rtl_count > ltr_count;
};

//@@---
export const rangeLog = (s: string, r: number[]) => {
  console.groupCollapsed(s);
  for (let ind = 0; ind < r.length; ind = ind + 2) {
    console.log(`[${r[ind].toFixed(3)}, ${r[ind + 1].toFixed(3)}]`);
  }
  console.groupEnd();
};
const dictorlog = (s: string, dictors: IDictor[], timeBegin: number, timeEnd: number) => {
  const dictorSet = new Set<IDictor>();
  //const dictorRangeLen: string[] = [];
  dictors.forEach(d => {
    const r = d.segmentation.ranges;
    rangeLog(`${d.id} ${d.name} |[ranges]|=${r.length / 2}(${r.length}); `, r);
    // console.groupCollapsed(`${d.id} ${d.name}`);
    // for (let ind = 0; ind < r.length; ind = ind + 2) {
    //   console.log(`[${r[ind].toFixed(3)}, ${r[ind + 1].toFixed(3)}]`);
    // }
    // console.groupEnd();
    d.segmentation.ranges.forEach(r => {
      if (r >= timeBegin && r <= timeEnd) {
        dictorSet.add(d);
      }
    });
  });
  console.log(
    `${s} dictores with range[${timeBegin},${timeEnd}] ${Array.from(dictorSet).map(d => `${d.id} ${d.name}`)}`,
  );
};

export const changeSegementationAcordingPhrase = (
  dictors: IDictor[],
  idDictorFrom?: number,
  idDictorTo?: number,
  timeBegin?: number,
  timeEnd?: number,
) => {
  if (!idDictorFrom || !idDictorTo || timeBegin === undefined || timeEnd === undefined) return [];
  console.groupCollapsed(`@@ changeSegementationAcordingPhrase ${timeBegin.toFixed(3)}-${timeEnd.toFixed(3)}`);
  const tmpDictors = [...dictors];

  dictorlog('@@---', tmpDictors, timeBegin, timeEnd);
  //@@===

  const indDictorMinus = tmpDictors.findIndex(d => d.id === idDictorFrom);
  const indDictorPlus = tmpDictors.findIndex(d => d.id === idDictorTo);
  if (indDictorMinus < 0 || indDictorPlus < 0) return [];
  console.log(
    ` dictorMinus=(${tmpDictors[indDictorMinus].id} ${tmpDictors[indDictorMinus].name})  to DictorPlus=(${tmpDictors[indDictorPlus].id} ${tmpDictors[indDictorPlus].name})
    `,
  );

  const segmMinus = tmpDictors[indDictorMinus].segmentation.ranges
    ? [...tmpDictors[indDictorMinus].segmentation.ranges]
    : [];
  segmMinus.sort((a, b) => +a - +b);
  let ind1 = 0;
  while (ind1 < segmMinus.length && segmMinus[ind1] < timeBegin) {
    ind1 = ind1 + 2;
  }
  console.log(`timeBegin=${timeBegin} [${ind1}]=${segmMinus[ind1]}`);
  if (ind1 >= segmMinus.length) {
    console.error(`segmentation of dictor ${tmpDictors[indDictorMinus].id} is bad`);

    return [];
  }
  let ind2 = ind1 - 1;
  do {
    ind2 = ind2 + 2;
  } while (ind2 < segmMinus.length && segmMinus[ind2] <= timeEnd);
  ind2 = ind2 - 2;
  console.log(`timeEnd=${timeEnd} [${ind2}]=${segmMinus[ind2]}`);
  // if (ind2 >= segmMinus.length) {
  //   ind2 = segmMinus.length - 2;
  // }

  //перемещаемый кусок сегментации
  rangeLog(`|[in-segmMinus]|=${segmMinus.length / 2}(${segmMinus.length})`, segmMinus);
  console.log(`[${ind1}]..[${ind2}]`);
  const times = segmMinus.splice(ind1, ind2 - ind1 + 1) ?? [];
  rangeLog(`|[times]|=${times.length / 2}(${times.length})`, times);
  rangeLog(`|[out-segmMinus]|=${segmMinus.length / 2}(${segmMinus.length})`, segmMinus);

  tmpDictors[indDictorMinus] = {
    ...tmpDictors[indDictorMinus],
    segmentation: { ...tmpDictors[indDictorMinus].segmentation, ranges: segmMinus },
  };

  const segmPlus: number[] = tmpDictors[indDictorPlus].segmentation.ranges
    ? [...tmpDictors[indDictorPlus].segmentation.ranges]
    : [];
  segmPlus.sort((a, b) => +a - +b);
  rangeLog(`|[in-segmPlus]|=${segmPlus.length / 2}(${segmPlus.length})`, segmPlus);
  let ind3 = 0;
  while (ind3 < segmPlus.length && segmPlus[ind3] < timeBegin) {
    ind3 += 2;
  }

  let rangesPlus: number[] = [];
  if (ind3 === 0) {
    rangesPlus = [...times, ...segmPlus];
  } else if (ind3 >= segmPlus.length) {
    rangesPlus = [...segmPlus, ...times];
  } else {
    rangesPlus = [...segmPlus.slice(0, ind3), ...times, ...segmPlus.slice(ind3)];
  }
  rangeLog(`|[out-rangesPlus]|=${rangesPlus.length / 2}(${rangesPlus.length})`, rangesPlus);

  rangesPlus.sort((a, b) => +a - +b);

  tmpDictors[indDictorPlus] = {
    ...tmpDictors[indDictorPlus],
    segmentation: { ...tmpDictors[indDictorPlus].segmentation, ranges: rangesPlus },
  };

  dictorlog('@@===', tmpDictors, timeBegin, timeEnd);

  console.groupEnd();

  return tmpDictors;
};

interface IPara {
  0: number;
  1: number;
}
interface ITextSeg {
  segm: IPara;
  text: string;
}

const intersection = (x: IPara, z: IPara) => {
  const a = Math.max(x[0], z[0]);
  const b = Math.min(x[1], z[1]);

  return a < b;
};
interface IDictorWithWordSegments {
  id: number;
  dictorNum: number;
  segmFromDict: IPara[];
  segmFromText: ITextSeg[];
  segmDop: IPara[];
  wordDop: ITextSeg[];
}
const analyse = (dwws: IDictorWithWordSegments) => {
  let ka = 0;
  let kt = 0;
  const audioRange = dwws.segmFromDict;
  const textRange = dwws.segmFromText;
  while (ka < audioRange.length && kt < dwws.segmFromText.length) {
    if (
      Math.abs(audioRange[ka][0] - textRange[kt].segm[0]) <= 0.05 ||
      intersection(audioRange[ka], textRange[kt].segm)
    ) {
      ka++;
      kt++;
    } else {
      if (audioRange[ka][1] <= textRange[kt].segm[0]) {
        dwws.segmDop.push(audioRange[ka]);
        ka++;
      } else {
        dwws.wordDop.push(textRange[kt]);
        kt++;
      }
    }
  }
  if (ka < audioRange.length) {
    dwws.segmDop.push(...audioRange.slice(ka));
  }
  if (kt < dwws.segmFromText.length) {
    dwws.wordDop.push(...textRange.slice(kt));
  }
};

const arr2par = (ar: number[], showDelta?: boolean): IPara[] => {
  const result: IPara[] = [];
  let delta = 0;
  for (let k = 0; k < ar.length; k = k + 2) {
    const x1 = Math.round(ar[k] * 100) / 100;
    const x2 = Math.round(ar[k + 1] * 100) / 100;
    if (Math.abs(x1 - ar[k]) > delta) {
      delta = Math.abs(x1 - ar[k]);
    }
    if (Math.abs(x2 - ar[k + 1]) > delta) {
      delta = Math.abs(x2 - ar[k + 1]);
    }
    const x: IPara = [x1, x2];
    result.push(x);
  }
  showDelta && console.log(delta);

  return result;
};

const tt2log = (value: Map<number, IDictorWithWordSegments>, logs: string[]) => {
  let err = false;
  const keys = [...value.keys()].sort();
  keys.forEach(key => {
    const dwws = value.get(key);
    if (dwws) {
      logs.push(
        `dictor id=${dwws.id} numer=${dwws.dictorNum}  have ${dwws.segmFromDict.length} segments ${
          dwws.segmDop.length > 0 ? `including ${dwws.segmDop.length} without words ` : ''
        }and ${dwws.segmFromText.length} words ${
          dwws.wordDop.length > 0 ? `including ${dwws.wordDop.length} out segmentaions ` : ''
        }`,
      );
      if (dwws.segmDop.length > 0 || dwws.wordDop.length > 0) {
        err = true;
        logs.push(`${
          dwws.segmDop.length === 0
            ? ''
            : `
segments of the dictor and not in words of the dictor: 
        ${dwws.segmDop.map(segm => `[${segm[0].toFixed(2)}-${segm[1].toFixed(2)}]`).join(', ')}`
        }${
          dwws.wordDop.length === 0
            ? ''
            : `
words not included in segments of the dictor:
        ${dwws.wordDop.map(word => word.text).join(', ')}  
        ${dwws.wordDop.map(word => `[${word.segm[0].toFixed(2)}-${word.segm[1].toFixed(2)}]`).join(', ')}  `
        }
--------------------------------------------------------`);
      }
    }
  });

  return err;
};

const tt2 = (record: IRecord, dictors: IDictor[], wordsData: IWordsData, logs: string[]) => {
  const segmByDictL = new Map<number, IDictorWithWordSegments>();
  const segmByDictR = new Map<number, IDictorWithWordSegments>();
  dictors.forEach(d => {
    const x: IDictorWithWordSegments = {
      id: d.id,
      dictorNum: d.dictorNum,
      segmFromDict: arr2par(d.segmentation.ranges, false),
      segmFromText: [],
      segmDop: [],
      wordDop: [],
    };
    d.channel === 'Left' ? segmByDictL.set(d.dictorNum, x) : segmByDictR.set(d.dictorNum, x);
  });

  wordsData.words.forEach(chanel => {
    const ch = chanel.channel === 'Left' ? segmByDictL : segmByDictR;
    chanel.words.forEach(w => {
      const dwws = ch.get(w.dictor_num);
      if (dwws) {
        dwws.segmFromText.push({ segm: [w.begin, w.end], text: w.text });
      } else {
        logs.push(`${w.dictor_num} has words and havn't segments`);
      }
    });
  });

  segmByDictL.forEach(dwws => analyse(dwws));
  segmByDictR.forEach(dwws => analyse(dwws));

  const errL = tt2log(segmByDictL, logs);
  const errR = tt2log(segmByDictR, logs);

  return errL || errR;
};

export const testSegmToWordsRange = (record: IRecord, dictors: IDictor[], wordsData: IWordsData | undefined) => {
  const logTitle = `There are problems at ${record.id} (${record.correlationId})`;

  if (dictors.length === 0 || (wordsData && wordsData?.words.length === 0)) {
    console.log(logTitle);
    console.log(`Dictors count: ${dictors.length}, wordData: ${wordsData?.words.length}`);

    return !(dictors.length === 0 && (!wordsData || wordsData.words.length === 0));
  }

  const logs: string[] = [];
  const error = (wordsData && tt2(record, dictors, wordsData, logs)) ?? false;

  if (error) {
    console.warn('vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv');
    console.groupCollapsed(logTitle);
    console.log('dictors correlations:', dictors.map(d => d.correlationId).join(','));
    console.log('wordData correlations:', wordsData?.correlationId);
    logs.forEach(v => console.log(v));
    console.groupEnd();
    console.warn('^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^');
  }

  return error;
};
