import { observer } from 'mobx-react';
import React, { useRef } from 'react';

import { CommonDialog, CommonDialogUiStore, ECommonDialogCloseStatus } from '@uk';

import { useTranslation } from 'react-i18next';

import { UserDialogBody, UserUpdate } from './user-dialog-body';
import { IUser } from './types';

export interface IUserDialogResult {
  user: UserUpdate;
  oldValue?: IUser;
  closeStatus: ECommonDialogCloseStatus;
}

interface IUserDialogProps {
  open: boolean;
  user?: IUser;
  onClose: (dialogResult: IUserDialogResult) => void;
}

const UserDialog: React.FC<IUserDialogProps> = ({ open, user, onClose }) => {
  const { t } = useTranslation();
  const isEditing = !!user;
  const userDlgUiStore = useRef(new CommonDialogUiStore());
  const localState = useRef<UserUpdate | undefined>(user);

  const handleClose = (status: number) => {
    const dlgResult = {
      user: localState.current,
      oldValue: user,
      closeStatus: status,
    } as IUserDialogResult;

    onClose(dlgResult);
  };

  return (
    <CommonDialog
      title={t('user')}
      open={open}
      onClose={handleClose}
      contentComponent={() => (
        <UserDialogBody
          user={user}
          onChange={(val: IUser | undefined) => {
            localState.current = val;
          }}
          setIsValid={v => userDlgUiStore.current.setOkEnabled(v)}
          isEditing={isEditing}
        />
      )}
      autotestId={'user'}
      uiStore={userDlgUiStore.current}
    />
  );
};

export default observer(UserDialog);
