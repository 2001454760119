import { backendApiUrl } from './constants';
import { BackendError, IBackendErrorInfo, ISendOptions } from './types';

import { gAPP_STORE } from '@/app/app-store';
import { LOCAL_STORAGE_USER_KEY } from '@/common/constants';

/* eslint-disable @typescript-eslint/no-explicit-any */

async function goodReply(response: Response, empty: null | '') {
  const text = await response.text();
  if (text.length > 0) {
    try {
      return JSON.parse(text);
    } catch (e) {
      return text;
    }
  }

  return empty;
}

async function badReply(response: Response, isRedirect = true): Promise<BackendError | undefined> {
  if (response.status === 403 && isRedirect && !response.url.endsWith('/file/register')) {
    gAPP_STORE.setStatusCodeError(403);
    localStorage.removeItem(LOCAL_STORAGE_USER_KEY);

    return new BackendError('Unauthorized', 401, {
      description: 'Unauthorized',
      stackTrace: 'Unauthorized',
      reasonCode: '###Unauthorized',
    });
  }

  if (response.status === 401 && isRedirect) {
    gAPP_STORE.setStatusCodeError(401);
    localStorage.removeItem(LOCAL_STORAGE_USER_KEY);
  }
  let responseJson;
  try {
    const text = await response.text();
    if (text.trim() !== '') {
      responseJson = JSON.parse(text);
    }
  } catch (ex) {
    console.error('Failed to parse response:', ex);
  }

  return new BackendError(response.statusText, response.status, responseJson as IBackendErrorInfo);
}

class CBackendService {
  /**
   * GET request
   *
   * @param controller
   * @param urlParamsj
   * @returns
   */
  get = async (controller: string, headOptions?: ISendOptions) => {
    const headers = new Headers();
    headers.append('accept', 'application/json;charset=UTF-8');
    headers.append('X-Requested-With', 'XMLHttpRequest'); //prevent browser auth POPup
    if (headOptions?.noAuth) {
      headers.append('authorization', headOptions?.sessionId ?? gAPP_STORE.loginStore.getAuthorizationString());
    }

    if (headOptions?.textContent) {
      headers.append('Content-Type', 'text/plain');
      headers.append('accept', 'text/plain');
    }

    if (headOptions?.noCache) {
      headers.append('Cache-Control', 'no-cache');
      headers.append('Pragma', 'no-cache');
      headers.append('Expires', '0');
    }

    const options = { method: 'GET', signal: headOptions?.abort?.signal, headers };
    let url = headOptions?.noPrefix === true ? '' : backendApiUrl;
    if (controller && controller.length > 0) {
      url += `/${controller}`;
    }
    if (headOptions?.urlParams && headOptions.urlParams.trim().length > 0) {
      url = `${url}?${headOptions.urlParams}`;
    }
    try {
      const request = new Request(url, options);

      const response = await fetch(request);

      const { status, ok } = response;

      if (ok && [200, 201, 204].includes(status)) {
        return goodReply(response, null);
      }

      throw await badReply(response, headOptions?.isRedirect);
    } catch (e) {
      throw e;
    }
  };

  /**
   * POST request
   *
   * @param controller
   * @param body
   * @returns
   */
  post = async (controller: string, body: any, options?: ISendOptions) => {
    return await this.postPut('POST', controller, body, options);
  };

  /**
   * PUT
   *
   * @param controller
   * @param body
   * @returns
   */
  put = async (controller: string, body: any, options?: ISendOptions) => {
    return await this.postPut('PUT', controller, body, options);
  };

  /**
   * DELETE
   *
   * @param controller
   * @param id
   * @returns
   */
  delete = async (controller: string, body?: any) => {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('X-Requested-With', 'XMLHttpRequest'); //prevent browser auth POPup

    const options = { method: 'DELETE', headers, body };
    let url = backendApiUrl;
    if (controller && controller.length > 0) {
      url += `/${controller}`;
    }

    try {
      const request = new Request(url, options);

      const response = await fetch(request);

      const { status, ok } = response;

      if (ok && status >= 200 && status < 300) {
        return goodReply(response, null);
      }

      throw await badReply(response);
    } catch (e) {
      throw e;
    }
  };

  /**
   * POST | PUT
   *
   * @param method
   * @param controller
   * @param body
   * @returns
   */
  postPut = async (method: string, controller: string, body: any, appOptions?: ISendOptions) => {
    const headers = new Headers();
    headers.append('X-Requested-With', 'XMLHttpRequest');
    if (appOptions?.textContent) {
      headers.append('Content-Type', 'text/plain');
      headers.append('accept', 'text/plain');
    } else if (appOptions?.uploadRecord) {
      headers.append('Content-Type', 'application/octet-stream');
    } else {
      headers.append('Content-Type', 'application/json');
    }

    headers.append('accept', 'application/json;charset=UTF-8');

    if (appOptions?.sessionId?.length) {
      headers.append('authorization', appOptions.sessionId);
    }

    if (appOptions?.jwt) {
      headers.append('authorization', appOptions.jwt);
    }

    const options = { method, signal: appOptions?.abort?.signal, headers, body };
    let url = appOptions?.noPrefix === true ? '' : backendApiUrl;
    if (controller && controller.length > 0) {
      url += `/${controller}`;
    }

    try {
      const request = new Request(url, options);

      const response = await fetch(request);

      const { status, ok } = response;

      if (ok && [200, 201, 204].includes(status)) {
        return goodReply(response, null);
      }

      throw await badReply(response, appOptions?.isRedirect);
    } catch (e) {
      throw e;
    }
  };
}

export const BackendService = new CBackendService();
