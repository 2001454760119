import { Box, makeStyles, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@material-ui/core';
import { styled, withStyles } from '@material-ui/core/styles';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';

import { observer } from 'mobx-react';
import React, { useCallback, useLayoutEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { IToolbarExDescriptorItem, ToolbarEx } from '@uk';

import { throttle } from 'lodash';

import { IFilterData } from '../filter/i-filter';

import FilterField from '../filter/filter-field';
import { IFilterFieldListItem } from '../filter/filter-field-list';
import { getFilterPredicate, getFilterScore, getFilterValue } from '../filter/filter-utils';
import { IFilterField, TFilterFieldValue, TFilterPredicate } from '../filter/i-filter-field';

import { gAPP_STORE } from '@/app/app-store';

const useStyles = makeStyles({
  root: {
    flexGrow: 0,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    width: '100%',
    backgroundColor: 'white',
  },
  toolbar: {
    flexGrow: 0,
  },
  tableContainer: {
    flexGrow: 1,
    backgroundColor: 'white',
    overflow: 'visible',
  },
  title: {
    background: '#eceff166',
    whiteSpace: 'nowrap',
    width: 1,
    paddingTop: '0.8em',
    paddingBottom: '0.8em',
    borderRight: '1px solid #ddd',
  },
  value: {
    textAlign: 'center',
  },
  input: {
    border: 'none',
    outline: 'none',
    width: '100%',
  },
  splitPane: {
    overflow: 'hidden',
    height: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
  },
});

export const FieldTableCell = withStyles({
  sizeSmall: {
    textAlign: 'center',
    '&:last-child': {
      padding: 0,
    },
  },
})(props => <TableCell {...props} />);

interface IUseScroll {
  ref: React.RefObject<HTMLElement>;
  onScroll: () => void;
}

const useScroll = (): IUseScroll => {
  const ref = useRef<HTMLElement>(null);
  const scroll = useMemo(
    () =>
      throttle(() => {
        localStorage.setItem('filterScrollPosition', `${ref?.current?.scrollTop}`);
      }, 100),
    [],
  );

  useLayoutEffect(() => {
    localStorage.setItem('filterScrollPosition', `${0}`);
  }, []);

  const onScroll = () => {
    scroll();
  };

  useLayoutEffect(() => {
    const scrollPos = +(localStorage.getItem('filterScrollPosition') ?? '0');
    ref.current?.scrollTo({
      top: scrollPos,
      left: 0,
    });
  });

  return {
    ref,
    onScroll,
  };
};

interface IFilterProps {
  getFilterFieldList: (descriptor: IFilterField) => IFilterFieldListItem[];
  filterFields: IFilterField[];
  handleFilterChange: (id: string, value: TFilterFieldValue | null | undefined, score?: number) => void;
  handleFilterPredicateChange: (id: string, value: TFilterPredicate) => void;
  handleKeyDown: (event: React.KeyboardEvent) => void;
  getNoTranslateValue: (descriptor: IFilterField) => boolean | undefined;
  filterData: IFilterData;
  handleFilterClear: () => void;
  handleFilterApply: () => void;
}

export const FilterCommon: React.FC<IFilterProps> = observer(
  ({
    getFilterFieldList,
    filterFields,
    handleFilterChange,
    handleFilterPredicateChange,
    handleKeyDown,
    getNoTranslateValue,
    filterData,
    handleFilterClear,
    handleFilterApply,
  }) => {
    const classes = useStyles();
    const SBox = styled(Box)({ overflowY: 'auto', overflowX: 'hidden' });

    const { t } = useTranslation();

    const { ref, onScroll } = useScroll();

    const atLeastOneValueIsNotEmpty = useCallback(() => {
      return !Object.entries(filterData).some(([key, item]) =>
        key !== 'topicThreshold' && item && item.value && item.value !== ''
          ? Array.isArray(item.value)
            ? item.value.length > 0
            : true
          : false,
      );
    }, [filterData]);

    const toolbarDescriptor: IToolbarExDescriptorItem[] = useMemo(() => {
      return [
        {
          type: 'button',
          text: 'apply',
          icon: <CheckRoundedIcon />,
          onClick: handleFilterApply,
          disabled: atLeastOneValueIsNotEmpty(),
        },
        {
          type: 'filler',
        },
        {
          type: 'button',
          text: 'clear',
          icon: <ClearRoundedIcon />,
          onClick: handleFilterClear,
        },
      ];
    }, [handleFilterApply, handleFilterClear, atLeastOneValueIsNotEmpty]);

    return (
      <Box display="flex" flexGrow={5} flexDirection="column" height={0}>
        <ToolbarEx descriptor={toolbarDescriptor} />
        <SBox {...{ ref: ref }} onScroll={onScroll} height="100%">
          <TableContainer className={classes.tableContainer}>
            <Table size="small">
              <TableBody>
                {filterFields.map((descriptor, index) => {
                  const list = getFilterFieldList(descriptor);
                  const noTranslateValue = getNoTranslateValue(descriptor);

                  return (
                    <TableRow
                      key={index}
                      style={{ borderTop: `${descriptor.newFilterBlock ? '7px solid rgba(236, 239, 241, 1)' : '0px'}` }}
                    >
                      <TableCell className={classes.title}>
                        <Typography>
                          {descriptor.title === 'records.meetingTitle'
                            ? !gAPP_STORE.meetingTitleColumnName
                              ? t('records.meetingTitle')
                              : (gAPP_STORE.meetingTitleColumnName as string)
                            : t(descriptor.title)}
                        </Typography>
                      </TableCell>
                      <FieldTableCell>
                        <FilterField
                          value={getFilterValue(filterData, descriptor.id)}
                          score={getFilterScore(filterData, descriptor.id)}
                          predicate={getFilterPredicate(filterData, descriptor.id)}
                          list={list}
                          descriptor={descriptor}
                          onChange={handleFilterChange}
                          onChangePredicate={handleFilterPredicateChange}
                          onKeyDown={handleKeyDown}
                          noTranslateValue={noTranslateValue}
                          multiple={descriptor.multiple}
                        />
                      </FieldTableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </SBox>
      </Box>
    );
  },
);
