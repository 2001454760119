import React, { useRef } from 'react';

import { useTranslation } from 'react-i18next';

import { IDataSourceEntity } from '../types';

import { DataSourceDialogBody } from './data-source-dialog-body';

import { CommonDialog, CommonDialogUiStore, ECommonDialogCloseStatus } from '@/react-ui-kit/src';

export interface ISourceDialogResult {
  source?: IDataSourceEntity;
  oldValue?: IDataSourceEntity;
  closeStatus: ECommonDialogCloseStatus;
}

export interface IFormValues {
  name: string;
  groups: (number | undefined)[];
  ciscoName: string;
  spaceID: string;
}

interface IDataSourceProps {
  open: boolean;
  source?: IDataSourceEntity;
  onClose: (dialogResult: ISourceDialogResult) => void;
}

/*
TODO: компонент диалогового окна надо переписывать на 
другую модель поведения, где DialogContent будет в виде children'a, 
а кнопки по типу close будут вынесены в виде DialogActions.
Это должно избавить нас от проблемы прокидывания стейта из contentComponent наверх.
*/
const DataSourceCreateDialog: React.FC<IDataSourceProps> = ({ open, source, onClose }) => {
  const { t } = useTranslation();
  const sourceDlgUiStore = useRef<CommonDialogUiStore>(new CommonDialogUiStore());

  const localState = useRef<IDataSourceEntity | undefined>(source);

  const handleClose = (status: ECommonDialogCloseStatus) => {
    const dlgResult = {
      source: localState.current,
      oldValue: source,
      closeStatus: status,
    } as ISourceDialogResult;
    onClose(dlgResult);
    localState.current = undefined;
  };

  return (
    <CommonDialog
      title={source ? t('dataSources.dialogs.editTitle') : t('dataSources.dialogs.createTitle')}
      open={open}
      onClose={handleClose}
      contentComponent={
        <DataSourceDialogBody
          source={source}
          onChange={val => {
            localState.current = val;
          }}
          setIsValid={v => sourceDlgUiStore.current.setOkEnabled(v)}
        />
      }
      autotestId={'dataSources'}
      uiStore={sourceDlgUiStore.current}
    />
  );
};

export default DataSourceCreateDialog;
