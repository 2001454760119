import { Box, Fade, Tab, Tabs } from '@material-ui/core';
import { observer } from 'mobx-react';
import { FC, ReactNode, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import AutoTranslateGrid from 'components/auto-translate/auto-translate-grid';
import LanguagesGrid from 'components/languages/languages-grid';

import GroupsGrid from '../groups/groups-grid';
import TemplatesGrid from '../templates/templates-grid';
import UsersGrid from '../users/users-grid';

import { DataSourcesPanel } from '../dataSources/data-sources-panel';
import EventLogPanel from '../eventLog/event-log-panel';

import { SettingsPageStore } from './SettingsPage.store';

import { gAPP_STORE } from '@/app/app-store';
import { LoadingPanel } from '@/react-ui-kit/src';

interface ITab {
  id: string;
  title: string;
  component: ReactNode;
}

const Settings: FC = observer(() => {
  const { t } = useTranslation();
  const [settingsPageStore] = useState(() => new SettingsPageStore());
  const { loadTab, setCurrentTab, currentTab: storeTab } = settingsPageStore;
  const isCurrentTabLoading = storeTab?.tabStore.isLoading();

  const tabs: ITab[] = useMemo(() => {
    const tt: ITab[] = [];
    tt.push({
      id: 'userSettings',
      title: 'settings.userSettings',
      component: <UsersGrid />,
    });

    if (gAPP_STORE.groups && (gAPP_STORE.loginStore.user?.isSupervisor || gAPP_STORE.loginStore.user?.isAdmin)) {
      tt.push({
        id: 'groupSettings',
        title: 'settings.groupSettings',
        component: <GroupsGrid />,
      });
    }

    tt.push({
      id: 'templateSettings',
      title: 'settings.templateSettings',
      component: <TemplatesGrid />,
    });
    tt.push({
      id: 'languageSettings',
      title: 'settings.languageSettings',
      component: <LanguagesGrid />,
    });

    gAPP_STORE.viewTranslate &&
      tt.push({
        id: 'autoTranslate',
        title: 'settings.autoTranslate',
        component: <AutoTranslateGrid />,
      });

    if (gAPP_STORE.loginStore.user?.isAdmin) {
      tt.push({
        id: 'eventLog',
        title: 'settings.eventLog',
        component: <EventLogPanel />,
      });
    }

    tt.push({ id: 'dataSources', title: 'settings.dataSources', component: <DataSourcesPanel /> });

    return tt;
  }, []);

  const [selectedTab, setSelectedTab] = useState<number>(parseInt(localStorage.getItem('selectedTab') as string) || 0);

  const currentTab = tabs[selectedTab];

  useEffect(() => {
    setCurrentTab(currentTab.id);
    loadTab(currentTab.id);
  }, [currentTab.id, loadTab, setCurrentTab]);

  return (
    <Fade in>
      <Box display="flex" flexGrow={1} flexDirection="column" style={{ background: '#E5E5E5' }}>
        <Box flexGrow={0}>
          <Tabs
            value={selectedTab}
            textColor="primary"
            onChange={(event, newValue) => {
              setSelectedTab(newValue);
              localStorage.setItem('selectedTab', newValue);
            }}
          >
            {tabs.map((tab, index) => (
              <Tab key={`tab-${tab.id}`} label={t(tab.title)} value={index} />
            ))}
          </Tabs>
        </Box>

        <Box display="flex" flexGrow={1} position="relative">
          {!isCurrentTabLoading && currentTab.component}
          {isCurrentTabLoading && <LoadingPanel />}
        </Box>
      </Box>
    </Fade>
  );
});
export const SettingsPage: FC = () => {
  return <>{gAPP_STORE.loginStore.user?.isAdmin && <Settings />}</>;
};
