import { observer } from 'mobx-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import * as yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';

import { useForm } from 'react-hook-form';

import { cloneDeep } from 'lodash';

import { FilterCommon } from '../common/common-filter';
import { IFilterFieldListItem, strListToFilterFieldList } from '../filter/filter-field-list';
import { FILTER_DATA_RELATIONS, IFilterData, TFilterDataFields } from '../filter/i-filter';
import { IFilterField, TFilterFieldValue, TFilterPredicate } from '../filter/i-filter-field';

import { handleFilterDate } from '../records/utils/handleFilterDate';

import {
  convertFilterFieldToData,
  evenLogDefaultFilterDescriptor,
  getFilterLinkedEventLog,
} from './event-log-filter-descriptor';
import {
  enumEventsToFilterFieldList,
  enumEventTypeToFilterFieldList,
  enumProcessingMethodsToFilterFieldList,
} from './event-log-filter-utils';
import { EEventType, EventTypeMap, topicTypes } from './types';

import { gAPP_STORE } from '@/app/app-store';
import { eventLogs } from '@/common/constants';
import { ChannelsService } from '@/services/channels/channels-service';
const fieldsToTrim = new Set(['record_name', 'filename', 'dictor_name', 'dictor_surname']);

type TFilterFields =
  | 'actionType'
  | 'loadFrom'
  | 'loadTo'
  | 'userId'
  | 'eventType'
  | 'login'
  | 'privilege'
  | 'group_name'
  | 'groups_names'
  | 'topic_name'
  | 'topic_type'
  | 'template_name'
  | 'format'
  | 'channel_name'
  | 'filename'
  | 'dictor_name'
  | 'dictor_surname'
  | 'record_name'
  | 'processing_method';

const EventLogFilter = observer(() => {
  const [filterFields, setFilterFields] = useState<IFilterField[]>(evenLogDefaultFilterDescriptor);
  const [eventTypus, setEventTypus] = useState<EEventType | undefined>(undefined);
  const [channels, setChannels] = useState<IFilterFieldListItem[]>([]);
  const eventLogStore = gAPP_STORE.getEventLogStore();

  const formSchema = useMemo(
    () =>
      yup.object({
        eventType: yup.array(yup.string().test(value => Boolean(value && EEventType.hasOwnProperty(value)))).optional(),
        userId: yup.array().max(5).optional(),
        actionType: yup.array().max(10).optional(),
        loadFrom: yup
          .string()
          .optional()
          .test(function (createFrom) {
            if (!createFrom) return true;

            const { loadTo } = this.parent;
            const notBiggerThanTo = loadTo ? new Date(createFrom) <= new Date(loadTo) : true;
            const notBiggerThanNow = new Date(createFrom) <= new Date();

            return notBiggerThanTo && notBiggerThanNow;
          }),
        loadTo: yup
          .string()
          .optional()
          .test(function (createTo) {
            if (!createTo) return true;

            const { loadFrom } = this.parent;
            const notBiggerThanFrom = loadFrom ? new Date(loadFrom) <= new Date(createTo) : true;
            const notBiggerThanNow = new Date(createTo) <= new Date();

            return notBiggerThanFrom && notBiggerThanNow;
          }),
        //from actionType
        login: yup.array(yup.string()).max(5).optional(), //пользователь
        privilege: yup.array(yup.string()),
        group_name: yup.array(yup.string()).max(5).optional(),
        groups_names: yup.array(yup.string()).max(5).optional(),
        topic_name: yup.array(yup.string()).max(5).optional(),
        topic_type: yup.array(yup.string()).optional(),
        template_name: yup.array(yup.string()).max(5).optional(),
        format: yup.array(yup.string()),
        channel_name: yup.array(yup.string()).max(5), //каналы
        filename: yup.array(yup.string().max(1200).required()).max(10).optional(),
        dictor_name: yup.array(yup.string().max(100).required()).max(10).optional(),
        dictor_surname: yup.array(yup.string().max(100).required()).max(10).optional(),
        record_name: yup.array(yup.string().max(1200).required()).max(10).optional(), //id встречи ?
        processing_method: yup.array(yup.string()),
      }),
    [],
  );
  const {
    register,
    reset,
    trigger,
    setValue,
    control,
    resetField,
    getValues,
    formState: { errors, isValid, touchedFields },
  } = useForm({
    resolver: yupResolver(formSchema),
    mode: 'all',
  });

  const getTopicNamesList = useCallback(() => {
    const list = gAPP_STORE.getWordDictionaryStore().data.wordDictionaryList.map(wd => ({
      id: wd.name,
      title: wd.name,
    }));

    return Array.from(new Map(list.map(item => [item.title, item])).values());
  }, []);

  const getAccessGroupNamesList = useCallback(() => {
    const list = gAPP_STORE.getGroupsStore().data.groups.map(group => ({
      id: group.name,
      title: group.name,
    }));

    return Array.from(new Map(list.map(item => [item.title, item])).values());
  }, []);

  const getTemplateNamesList = useCallback(() => {
    const list = gAPP_STORE.getTemplateStore().data.templates.map(template => ({
      id: template.templateName,
      title: template.templateName,
    }));

    return Array.from(new Map(list.map(item => [item.title, item])).values());
  }, []);

  const resetfilter = useCallback(() => {
    eventLogStore.setFilterData(convertFilterFieldToData(evenLogDefaultFilterDescriptor));
    setFilterFields(evenLogDefaultFilterDescriptor);
  }, [eventLogStore]);

  useEffect(() => {
    const requestAndSet = async () => {
      const data = await ChannelsService.getAll();
      if (data) {
        const uniqueChannels = Array.from(
          new Map(data.map(channel => [channel.name, { id: channel.name, title: channel.name }])).values(),
        );
        setChannels(uniqueChannels);
      }
    };
    requestAndSet();
  }, []);

  const setFilterData = useCallback(
    f => {
      eventLogStore.setFilterData(f());
    },
    [eventLogStore],
  );

  const applyFilter = useCallback(() => {
    eventLogStore.reload();
  }, [eventLogStore]);

  useEffect(() => {
    resetfilter();

    return () => {
      resetfilter();
      applyFilter();
    };
  }, [applyFilter, resetfilter]);

  useEffect(() => {
    const newfilterData = convertFilterFieldToData([...filterFields]);

    const store = eventLogStore;
    for (const filter in newfilterData) {
      newfilterData[filter as keyof IFilterData] =
        store.filterData[filter as keyof IFilterData] ?? newfilterData[filter as keyof IFilterData];
    }

    store.setFilterData(newfilterData);
  }, [eventLogStore, filterFields]);
  const { t } = useTranslation();

  const handleFilterPredicateChange = useCallback(
    (id: string, value: TFilterPredicate) => {
      setFilterData(() => {
        const newFilterData = JSON.parse(JSON.stringify(eventLogStore.filterData));
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const newFilterDataField: any = newFilterData[id as TFilterDataFields]
          ? newFilterData[id as TFilterDataFields]
          : {};

        newFilterDataField.predicate = value;

        return newFilterData;
      });
    },
    [eventLogStore.filterData, setFilterData],
  );

  const getUser = useCallback(() => {
    return gAPP_STORE.getUsersStore().data.users.map(u => ({
      id: u.id.toString(),
      title: `${u.email}`,
    }));
  }, []);

  const getUserLogins = useCallback(() => {
    return gAPP_STORE.getUsersStore().data.users.map(u => ({
      id: `${u.email}`,
      title: `${u.email}`,
    }));
  }, []);

  const handleFilterApply = useCallback(() => {
    applyFilter();
  }, [applyFilter]);

  const handleFilterClear = useCallback(() => {
    resetfilter();
    applyFilter();
  }, [applyFilter, resetfilter]);

  const eventTypes = useMemo(() => enumEventTypeToFilterFieldList('eventType'), []);
  const processingMethods = useMemo(() => enumProcessingMethodsToFilterFieldList(), []);

  const actionTypes = useMemo(
    () => enumEventsToFilterFieldList(eventLogStore.getEventListByTypus(eventTypus), 'actionType'),
    [eventLogStore, eventTypus],
  );

  const getFilterFieldList = useCallback(
    (descriptor: IFilterField): IFilterFieldListItem[] => {
      switch (descriptor.id) {
        case 'actionType':
          return actionTypes;

        case 'eventType':
          return eventTypes;

        case 'processing_method':
          return processingMethods;

        case 'channel_name':
          return channels;

        case 'login':
          return getUserLogins();

        case 'userId':
          return getUser();

        case 'format':
          return strListToFilterFieldList(['pdf', 'docx']);

        case 'template_name':
          return getTemplateNamesList();

        case 'group_name':
        case 'groups_names':
          return getAccessGroupNamesList();

        case 'topic_type':
          return strListToFilterFieldList(topicTypes);

        case 'topic_name':
          return getTopicNamesList();

        default:
          return [];
      }
    },
    [
      actionTypes,
      channels,
      eventTypes,
      getAccessGroupNamesList,
      getTemplateNamesList,
      getTopicNamesList,
      getUser,
      getUserLogins,
      processingMethods,
    ],
  );
  const handleFilterChange = useCallback(
    async (id: string, pureValue: TFilterFieldValue | null | undefined, score?: number) => {
      //#region Validation
      const prevValue = getValues()[id as TFilterFields];
      let value = pureValue;
      //#region date
      const relatedFields: { id: TFilterDataFields; value: IFilterField }[] = [];
      const relatedKey = FILTER_DATA_RELATIONS[id as TFilterDataFields];

      if (typeof value === 'string' && id === 'loadFrom') {
        const to: string | undefined = relatedKey && control._formValues[relatedKey];
        const date = handleFilterDate({ from: value, to });
        date.from && (value = date.from);
        const filterField = relatedKey && cloneDeep(eventLogStore.filterData[relatedKey]);
        if (date.to && filterField) {
          filterField.value = date.to;
          setValue(relatedKey as TFilterFields, filterField.value as string);
          relatedKey && relatedFields.push({ id: relatedKey, value: filterField });
        }
      }
      if (typeof value === 'string' && id === 'loadTo') {
        const from: string | undefined = relatedKey && control._formValues[relatedKey];
        const date = handleFilterDate({ from, to: value });
        date.to && (value = date.to);
        const filterField = relatedKey && cloneDeep(eventLogStore.filterData[relatedKey]);
        if (date.from && filterField) {
          filterField.value = date.from;
          setValue(relatedKey as TFilterFields, filterField.value as string);
          relatedKey && relatedFields.push({ id: relatedKey, value: filterField });
        }
      }

      //#endregion
      if (fieldsToTrim.has(id) && Array.isArray(value)) {
        value = value.map(item => item.trim()).filter(Boolean);
      }

      setValue(id as TFilterFields, value as string);
      await trigger([id as TFilterFields]);

      if (control.getFieldState(id as TFilterFields).invalid) {
        eventLogStore.onFilterFieldChange(id as TFilterFields);
        setValue(id as TFilterFields, prevValue);

        return;
      }
      //#endregion

      //!FIXME не заносите вызов setFilterFields внутрь функции ниже, она вызывается в сторе, вне компонента, вообще отвязана от жизненного цикла компонента
      if (id === 'actionType' && value) {
        const paramList = eventLogStore.getParametres((value as string[]).slice(0, eventLogs.MAX_ACTIONS));
        const newFilterFileds = [...evenLogDefaultFilterDescriptor, ...getFilterLinkedEventLog(paramList)];

        setFilterFields(newFilterFileds);
      }

      setFilterData(() => {
        const newFilterData = cloneDeep(eventLogStore.filterData);
        relatedFields.forEach(relatedField => {
          newFilterData[relatedField.id] = relatedField.value;
        });

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const newFilterDataField: any = newFilterData[id as TFilterDataFields]
          ? newFilterData[id as TFilterDataFields]
          : {};

        if (id === 'topic') {
          newFilterData.topic = { value: value ? value : [], score } as IFilterField;

          return newFilterData;
        }

        if (Array.isArray(value)) {
          let goodvalues: string[];
          switch (id) {
            case 'eventType':
              goodvalues = Array.from(EventTypeMap.values());
              break;
            case 'userId':
              goodvalues = getUser().map(item => item.id);
              break;
            case 'actionType':
              if (value.length > eventLogs.MAX_ACTIONS) {
                toast.warn(t('actionTypeMaxItems', { actionTypeMaxItems: eventLogs.MAX_ACTIONS }));
              }
              goodvalues = value.slice(0, eventLogs.MAX_ACTIONS);
              break;

            default:
              goodvalues = value;
          }
          newFilterDataField.value = value.filter(v => goodvalues.includes(v));
        } else {
          newFilterDataField.value = value;
        }
        newFilterDataField.score = score;

        return newFilterData;
      });
    },
    [control, eventLogStore, getUser, getValues, setFilterData, setValue, t, trigger],
  );

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === 'Enter') {
        applyFilter();
      }
    },
    [applyFilter],
  );

  const getNoTranslateValue = useCallback((descriptor: IFilterField): boolean | undefined => {
    let result = undefined;
    if (descriptor.id === 'dictors' || descriptor.id === 'topic') {
      result = true;
    }

    return result;
  }, []);

  return (
    <>
      <FilterCommon
        getFilterFieldList={getFilterFieldList}
        filterFields={filterFields}
        handleFilterChange={handleFilterChange}
        handleFilterPredicateChange={handleFilterPredicateChange}
        handleKeyDown={handleKeyDown}
        getNoTranslateValue={getNoTranslateValue}
        filterData={eventLogStore.filterData}
        handleFilterApply={handleFilterApply}
        handleFilterClear={handleFilterClear}
      />
    </>
  );
});

export default EventLogFilter;
