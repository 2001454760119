import { gAPP_STORE } from 'app/app-store';
import { capitalizeFirstLetter } from 'common/utils';

import { IChannelWords, IPhrase, IWord, IWordsData } from '../types';

import { IDictor } from '@/types/dictors';

const terminalStopSigns = new Set(['.', '!', '?', '؟']);

export const isRtlLanguage = (langCode?: string): boolean => {
  if (!langCode) {
    return false;
  }

  return gAPP_STORE.rtlLanguages.includes(langCode);
};

/**
 * Строит фразу из массива слов
 * @param words
 * @param phrasePauseSec
 * @param sentencePauseSec
 * @param dictors
 */
export const wordsToPhrases = (
  wordsData: IWordsData | undefined,
  phrasePauseSec: number,
  sentencePauseSec: number | undefined,
  dictors: IDictor[],
): IPhrase[] => {
  if (!wordsData || !wordsData.words) {
    return [];
  }

  const words: IWord[] = [];

  wordsData.words.forEach((channelWords: IChannelWords, channelWordsIndex) => {
    words.push(
      ...(channelWords.words || []).map((word: IWord) => {
        const dictor_num = word.dictor_num;

        return {
          ...word,
          channelNumber: channelWordsIndex, // Если стерео, то определяем номер канала (для раскладывания в диалог), иначе номер диктора
          channelName: channelWords.channel,
          dictor_num,
        };
      }),
    );
  });

  const tokens = words ? words.sort((a, b) => (a.begin === b.begin ? 0 : a.begin < b.begin ? -1 : 1)) : [];

  const phraseList: IPhrase[] = [];
  let channelNumber: number | undefined = undefined;
  let speakerNumber: number | undefined = undefined;

  function setStopSign(word: IWord, stopSign: string) {
    if (!word.stop_sign || ![...word.stop_sign].some(s => terminalStopSigns.has(s))) {
      word.stop_sign = stopSign;
    }
  }

  function addToPhrase(t: IWord, phrase: IPhrase) {
    phrase.tokens.push(t);
    phrase.textLen = phrase.textLen + t.text.length + (t.stop_sign ? t.stop_sign.length : 0);
  }

  let ti = 0;
  for (; ti < tokens.length; ti++) {
    const t = tokens.at(ti);
    if (!t) continue;

    phraseList.push({
      tokens: [],
      textLen: 0,
      channelNumber: t.channelNumber,
      channelName: t.channelName,
      speakerNumber: t.dictor_num,
      dictor: dictors.find(
        d =>
          t.channelName &&
          d.channel &&
          d.channel.toUpperCase() === t.channelName.toUpperCase() &&
          d.dictorNum === t.dictor_num,
      ),
    });
    channelNumber = t.channelNumber;
    speakerNumber = t.dictor_num;

    const lastPhrase = phraseList[phraseList.length - 1];

    addToPhrase(t, lastPhrase);

    // Добавим слова в фразу по установленному порогу длительности

    let i = ti + 1;
    for (; i < tokens.length; i++) {
      const t2 = tokens.at(i);

      if (!t2) {
        continue;
      }

      if (phrasePauseSec > 0 && t2.begin > t.end + phrasePauseSec) {
        break;
      }

      if (t2.channelNumber !== lastPhrase.channelNumber || t2.dictor_num !== lastPhrase.speakerNumber) {
        break;
      }
      if (t2.channelNumber === lastPhrase.channelNumber && t2.dictor_num === lastPhrase.speakerNumber) {
        addToPhrase(t2, lastPhrase);
      }
    }
    ti = i - 1; //step back to token
  }

  // set punctuation and fill display text

  phraseList.forEach(phrase => {
    phrase.tokens
      .filter(token => token.text && token.text.trim().length > 0)
      .forEach((token, index, arr) => {
        if (sentencePauseSec || sentencePauseSec === 0) {
          if (index === 0) {
            // first word in phrase
            token.text = capitalizeFirstLetter(token.text);
          } else {
            if (index === arr.length - 1) {
              // last word in phrase
              setStopSign(token, '.');
            } else {
              const prevToken = arr[index - 1];

              if (Math.abs(prevToken.end - token.begin) > sentencePauseSec) {
                setStopSign(prevToken, '.');
                token.text = capitalizeFirstLetter(token.text);
              }
              if (token.stop_sign && [...token.stop_sign].some(s => terminalStopSigns.has(s))) {
                token.stop_sign = null;
              }
            }
          }
        }
      });
  });

  phraseList.forEach((value, index) => {
    if (index === 0) {
      value.begin = 0;
    } else {
      value.begin = phraseList[index - 1].end;
    }
    value.end = value.tokens[value.tokens.length - 1].end;
  });

  return phraseList;
};

export const getPhraseWordText = (word: IWord): string => {
  return word.stop_sign ? word.text + word.stop_sign : word.text;
};

export const clearTokens = (tokens: IWord[]): IWord[] => {
  return tokens.map(t => ({
    begin: t.begin,
    dictor_num: t.dictor_num,
    end: t.end,
    probability: t.probability,
    stop_sign: t.stop_sign,
    text: t.text,
  }));
};

export const correctIm = (wordsData: IWordsData) => {
  wordsData?.words?.forEach(cw =>
    cw.words.forEach(w => {
      if (w.text === 'i') w.text = 'I';
      if (w.text === "i'm") w.text = "I'm";
    }),
  );

  return wordsData;
};

export const sortHistoryWordsData = (wordsData: IWordsData[], newestFirst?: boolean) => {
  return wordsData.sort((a, b) => {
    const d1 = Date.parse(a.createdDate);
    const d2 = Date.parse(b.createdDate);

    return d1 === d2 ? 0 : newestFirst ? d2 - d1 : d1 - d2;
  });
};

export const wordsToСhannelText = (words: IWord[]): IPhrase => {
  const textLen = words.reduce((sum, w) => sum + w.text.length, 0);

  return { tokens: words, textLen };
};

export const cuttingPhrase = (phrase: IPhrase, dictor: IDictor, fromIndex: number, uptoIndex: number) => {
  const b1 = { ...phrase };
  const b2 = { ...phrase };
  const b3 = { ...phrase };
  //поделили токены между фразами
  b1.tokens = fromIndex > 0 ? phrase.tokens.slice(0, fromIndex) : [];
  b2.tokens = phrase.tokens.slice(fromIndex, uptoIndex + 1);
  b3.tokens = uptoIndex + 1 < phrase.tokens.length ? phrase.tokens.slice(uptoIndex + 1) : [];
  //прописали для каждой фразы начало и конец на основе токенов
  //логику см. в конце функции wordsToPhrases
  if (b1.tokens.length > 0) {
    b1.end = b1.tokens[b1.tokens.length - 1].end;
    b2.begin = b1.end;
  }
  if (b2.tokens.length > 0) {
    b2.end = b2.tokens[b2.tokens.length - 1].end;
    b3.begin = b2.end;
  }
  if (b3.tokens.length === 0) {
    b3.end = b3.begin;
  }
  //заменили у веделеной фразы и токенов(слов) в ней диктора
  b2.dictor = dictor;
  const dictor_num = dictor.dictorNum;
  b2.tokens.forEach(w => {
    w.dictor_num = dictor_num;
  });
  b2.speakerNumber = dictor_num;
  b1.textLen = b1.tokens.reduce((len, t) => len + t.text.length, 0);
  b2.textLen = b2.tokens.reduce((len, t) => len + t.text.length, 0);
  b3.textLen = b3.tokens.reduce((len, t) => len + t.text.length, 0);

  return { b1, b2, b3 };
};

export const copyPhrasesWithCuttedChange = (
  phrases: IPhrase[],
  phraseIndex: number,
  b1: IPhrase,
  b2: IPhrase,
  b3: IPhrase,
) => {
  const result = phraseIndex > 0 ? [...phrases.slice(0, phraseIndex)] : [];
  if (b1.tokens.length > 0) result.push(b1);
  result.push(b2);
  if (b3.tokens.length > 0) result.push(b3);
  if (phraseIndex < phrases.length - 1) result.push(...phrases.slice(phraseIndex + 1));

  return result;
};

export const covertTextToWords = (editedPhrase: IPhrase, newText: string) => {
  const newPhraseWords: IWord[] = [];
  const regex = /([\n\r]+)|[\t\f\v ]*([^\s?,.;:!؟،؛]*)[\t\f\v ]*([?,.;:!؟،؛]+)*[\t\f\v ]*/g;
  for (const match of newText.matchAll(regex)) {
    const newLine = match[1];
    const wordStr = match[2];
    const stopSignStr = match[3];

    if (newLine && newLine.length > 0 && newPhraseWords.length > 0) {
      const w: IWord = {
        begin: 0,
        dictor_num: editedPhrase.speakerNumber ?? 0,
        end: 0,
        probability: 100,
        text: '',
        stop_sign: newLine,
      };

      newPhraseWords.push(w);
    }

    if (wordStr !== null && wordStr !== undefined) {
      const w: IWord = {
        begin: 0,
        dictor_num: editedPhrase.speakerNumber ?? 0,
        end: 0,
        probability: 100,
        text: wordStr,
        stop_sign: null,
      };

      if (stopSignStr) {
        w.stop_sign = stopSignStr;
      }

      if (stopSignStr || wordStr) newPhraseWords.push(w);
    }
  }

  let realText = '';
  const wordsCount = newPhraseWords.filter(w => w.text.trim().length > 0).length;
  const hasText = wordsCount > 0;

  if (newPhraseWords.length > 0) {
    const minTime = editedPhrase.tokens[0].begin;
    const maxTime = editedPhrase.tokens[editedPhrase.tokens.length - 1].end;
    const dt = (maxTime - minTime) / wordsCount;
    let beginWord = minTime;

    newPhraseWords.forEach((w, idx) => {
      realText += idx === 0 ? getPhraseWordText(w) : ` ${getPhraseWordText(w)}`;
      if (w.text.trim().length > 0) {
        w.begin = beginWord;
        w.end = beginWord + dt;
        beginWord = beginWord + dt;
      } else {
        w.begin = beginWord;
        w.end = beginWord;
      }
    });
  }

  return { hasText, realText, newPhraseWords };
};
