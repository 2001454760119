import { useState } from 'react';

import { Box } from '@material-ui/core';

import AddIcon from '@material-ui/icons/Add';

import EditIcon from '@material-ui/icons/Edit';

//import ClearRoundedIcon from '@material-ui/icons/ClearRounded';

import { useTranslation } from 'react-i18next';

import { observer } from 'mobx-react';

import { DataGrid, IToolbarExDescriptorItem, Panel, ToolbarEx } from '@uk';

import { toast } from 'react-toastify';

import UserDialog, { IUserDialogResult } from './user-dialog';
import { usersGridColumns } from './users-grid-columns';

import { gAPP_STORE } from '@/app/app-store';

const UsersGrid = () => {
  const { t } = useTranslation();

  let cols = usersGridColumns;
  if (!gAPP_STORE.groups) {
    cols = cols.filter(col => col.id !== 'groups');
  }

  const [openCreateUserDialog, setOpenCreateUserDialog] = useState(false);
  const [openEditUserDialog, setOpenEditUserDialog] = useState(false);

  const selectedUser = gAPP_STORE.getUsersStore().gridStore.selectedRow;

  const handleCloseUserDialog = async (dialogResult: IUserDialogResult) => {
    setOpenCreateUserDialog(false);
    setOpenEditUserDialog(false);

    if (dialogResult.closeStatus === 1) {
      const userStore = gAPP_STORE.getUsersStore();

      // Определяем новые и старые группы (если они есть)
      const oldGroupIds = (selectedUser?.groups || []).map(g => g.id);
      const newGroupIds = (dialogResult.user.groups || []).map(g => g.id);

      const addedGroupsIds = newGroupIds.filter(newId => oldGroupIds.findIndex(oldId => oldId === newId) < 0);
      const removedGroupsIds = oldGroupIds.filter(oldId => newGroupIds.findIndex(newId => oldId === newId) < 0);

      if (dialogResult.user.id !== -1 && dialogResult.oldValue) {
        // Обновление пользователя
        await userStore.update(dialogResult.user, dialogResult.oldValue);
        if (dialogResult.user.password && dialogResult.user.password.length > 0) {
          await userStore.updatePassword(dialogResult.user, dialogResult.user.password);
        }

        // Изменение групп пользователя
        if (addedGroupsIds.length > 0 || removedGroupsIds.length > 0) {
          await gAPP_STORE.getGroupsStore().updateUserGroups(dialogResult.user.id, removedGroupsIds, addedGroupsIds);
        }

        await userStore.gridStore.reload();
      } else {
        // Создание пользователя
        const newUser = await userStore.create(dialogResult.user, dialogResult.user.password || '');

        if (newUser) {
          // Обновляем ID пользователя для создания связей с группами
          const userId = newUser.id;

          // Теперь обновляем группы для нового пользователя
          if (addedGroupsIds.length > 0 || removedGroupsIds.length > 0) {
            await gAPP_STORE.getGroupsStore().updateUserGroups(userId, removedGroupsIds, addedGroupsIds);
          }

          await userStore.gridStore.reload();
        }
      }
    }
  };

  const handleCreateUser = () => {
    setOpenCreateUserDialog(true);
  };

  const handleEditUser = () => {
    if (!selectedUser) return;
    if (selectedUser.id === gAPP_STORE.loginStore.user?.id) {
      toast.info(t('users.cannotEditAdminUser'));
    } else {
      setOpenEditUserDialog(true);
    }
  };

  // const handleDeleteUser = async () => {
  //   const selectedUser = gAPP_STORE.getUsersStore().gridStore.selectedRow;
  //   if (selectedUser) {
  //     if (selectedUser.id === gAPP_STORE.loginStore.user?.id) {
  //       toast.info(t('users.cannotDeleteAdminUser'));
  //     } else {
  //       if (window.confirm(t('users.deleteConfirm'))) {
  //         await gAPP_STORE.getUsersStore().delete(selectedUser);
  //         await gAPP_STORE.getUsersStore().gridStore.reload();
  //       }
  //     }
  //   }
  // };

  const toolbarDescriptor: IToolbarExDescriptorItem[] = [
    { type: 'text', text: `${t('users.title')}`, pr: 1 },
    { type: 'divider', pl: 1 },
    {
      type: 'button',
      text: 'create',
      icon: <AddIcon />,
      onClick: handleCreateUser,
      pl: 1,
      disabled: gAPP_STORE.loginStore.user?.isAD,
    },
    {
      type: 'button',
      text: 'edit',
      icon: <EditIcon />,
      onClick: handleEditUser,
      pl: 1,
      disabled: gAPP_STORE.getUsersStore().gridStore.selectedRow ? false : true,
    },
    // {
    //   type: 'button',
    //   text: 'delete',
    //   icon: <ClearRoundedIcon />,
    //   onClick: handleDeleteUser,
    //   color: 'red',
    //   pl: 1,
    // },
  ];

  return (
    <Panel>
      <ToolbarEx descriptor={toolbarDescriptor} />
      <Box display="flex" flexGrow={1}>
        <DataGrid
          id="UsersGrid"
          cols={gAPP_STORE.getUsersStore().gridCols}
          gridStore={gAPP_STORE.getUsersStore().gridStore}
          inscriptions={gAPP_STORE.tableInscriptions}
          isNeedTooltipForContent={true}
          noPaging
        />
      </Box>

      <UserDialog
        open={openCreateUserDialog || openEditUserDialog}
        onClose={handleCloseUserDialog}
        user={openEditUserDialog ? selectedUser : undefined}
      />
    </Panel>
  );
};

export default observer(UsersGrid);
