import { reaction } from 'mobx';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ClickAwayListener, makeStyles, MenuItem, MenuList, Popper, Tooltip } from '@material-ui/core';

import { ITimecode, ITimecodesItem } from './types';

import { gAPP_STORE } from '@/app/app-store';
import { Z_INDEX_POPPER } from '@/common/constants';
import { IconTimeCode } from '@/components/summary/AIicons';
import { secondsToTimeString } from '@/utils/timeStamp';

const useStyles = makeStyles({
  counter: {
    height: '16px',
    borderRadius: '8px',
    border: '2px solid #6C737F',
    margin: 0,
    padding: '0 5px',
    fontSize: '10px',
    lineHeight: '12px',
    textAlign: 'center',
    fontWeight: 700,
    fontFamily: 'Inter, sans-serif',
    backgroundColor: '#fff',
    cursor: 'pointer',
    marginTop: '3px',
  },
  tcButton: {
    height: '24px',
    width: '24px',
    padding: '3px 0 0 2px',
    borderRadius: '4px',
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    '&:hover:not(:disabled)': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
  },
  tcButtonDisabled: {
    cursor: 'auto',
  },
  isActive: {
    backgroundColor: '#6C737F',
    color: '#fff',
  },
});

interface ITimecodes {
  timecodesItem?: ITimecodesItem;
  onShowOnPlayer: () => void;
  onClickTimeCode: (timecode: ITimecode) => void;
  autotest: string;
}

export const Timecodes: FC<ITimecodes> = ({ timecodesItem, onShowOnPlayer, onClickTimeCode, autotest }) => {
  const store = gAPP_STORE.summaryStore;
  const [showTimecodes, setShowTimecodes] = useState(false);
  const [isCounterActive, setIsCounterActive] = useState(store.isCounterActive(timecodesItem?.uuid));
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  useEffect(() => {
    return reaction(
      () => store.isCounterActive(timecodesItem?.uuid),
      (arg: boolean, prev: boolean) => {
        if (arg !== prev) {
          setIsCounterActive(arg);
        }
      },
    );
  }, [isCounterActive, store, timecodesItem]);

  const { t } = useTranslation();
  const styles = useStyles();
  const disabled = timecodesItem === undefined;

  const handleCounterClick = useCallback(() => {
    setIsCounterActive(prev => !prev);
    gAPP_STORE.summaryStore.setVisibleTimecodes(isCounterActive ? undefined : timecodesItem);
    onShowOnPlayer();
  }, [isCounterActive, onShowOnPlayer, timecodesItem]);

  const handleCloseMenu = () => {
    setShowTimecodes(false);
  };

  const handleClickTimeCode = useCallback(
    (index: number, timecode: ITimecode) => {
      if (
        store.currentTimecodeUuid &&
        timecodesItem &&
        timecodesItem.uuid === store.currentTimecodeUuid &&
        index === store.selectedTimeCodeIndex
      ) {
        store.setVisibleTimecodes(undefined);
      } else {
        store.setVisibleTimecodes({
          uuid: timecodesItem ? timecodesItem.uuid : '',
          timecodes: [timecode],
        });
        store.selectTimeCode(index, timecodesItem?.uuid);
      }
      onClickTimeCode(timecode);
      handleCloseMenu();
    },
    [onClickTimeCode, store, timecodesItem],
  );

  if (!gAPP_STORE.showTimecodes) {
    return <></>;
  }

  return (
    <div style={{ display: 'inline-flex', gap: '2px', width: '46px', justifyContent: 'end' }}>
      {timecodesItem && (
        <button
          data-autotest={`${autotest}-timecodeCount`}
          onClick={handleCounterClick}
          className={`${styles.counter} ${isCounterActive ? styles.isActive : ''}`}
        >
          {timecodesItem.timecodes.length}
        </button>
      )}

      <Tooltip data-autotest={`${autotest}-tooltip`} title={disabled ? '' : t('summary.timecodes')}>
        <button
          data-autotest={`${autotest}-timecodeButtonOpenMenu`}
          ref={anchorRef}
          className={`${styles.tcButton} ${disabled ? styles.tcButtonDisabled : ''}`}
          disabled={!timecodesItem}
          onClick={() => {
            if (timecodesItem?.timecodes.length === 1) {
              handleClickTimeCode(0, timecodesItem.timecodes[0]);
              setShowTimecodes(prev => !prev);
            } else {
              setShowTimecodes(prev => !prev);
            }
          }}
        >
          <IconTimeCode color="#0092DD" disabled={disabled} />
        </button>
      </Tooltip>
      <Popper
        anchorEl={anchorRef.current}
        style={{
          zIndex: Z_INDEX_POPPER,
          maxHeight: '50vh',
          overflowY: 'scroll',
        }}
        open={showTimecodes}
      >
        <ClickAwayListener onClickAway={handleCloseMenu}>
          <MenuList
            data-autotest={`${autotest}-menu`}
            style={{
              width: '243px',
              borderRadius: '4px',
              padding: '8px 0',
              backgroundColor: '#fff',
              boxShadow: '0 2px 16px 0 rgba(0, 0, 0, 0.24)',
            }}
          >
            {timecodesItem &&
              timecodesItem.timecodes.map((timecode, index) => (
                <MenuItem
                  key={`timeCode-${index}`}
                  data-autotest={`${autotest}-item-${index}`}
                  selected={index === store.selectedTimeCodeIndex}
                  onClick={() => handleClickTimeCode(index, timecode)}
                  style={{
                    padding: '10px 16px',
                    fontSize: '16px',
                    fontFamily: 'Inter, sans-serif',
                    color: '#0092DD',
                  }}
                >
                  {secondsToTimeString(timecode.begin)}
                </MenuItem>
              ))}
          </MenuList>
        </ClickAwayListener>
      </Popper>
    </div>
  );
};
