import { IChannelWords, IWordsData } from '@/components/records/types';
import { BackendService } from '@/services/backend-service';
import { IDictor } from '@/types/dictors';
import { checkAndThrowIfAborted } from '@/utils/abortUtils';

class TextService {
  getHistory = async (correlationId: string, isTranslate: boolean, abort?: AbortController): Promise<IWordsData[]> => {
    const body = {
      correlationId,
      limit: 1000,
      offset: 0,
      sortOrder: 'Asc',
    };
    try {
      return await BackendService.post(`text/${isTranslate ? 'translate/' : ''}history`, JSON.stringify(body), {
        abort,
      });
    } catch (error) {
      checkAndThrowIfAborted(error, true, 'textService');

      console.error('get History: ', { error });

      throw error;
    }
  };

  updateText = async (
    correlationId: string,
    isTranslation: boolean,
    words: IChannelWords[] | undefined,
  ): Promise<IWordsData> => {
    const body = {
      correlationId,
      words,
    };

    return await BackendService.post(`text/${isTranslation ? 'translate/' : ''}update`, JSON.stringify(body));
  };

  updateSegmentation = async (recordId: number, dictors: IDictor[]): Promise<void> => {
    const dictorsEntryList = dictors.map(dictor => {
      return {
        ...dictor,
        metadata: JSON.stringify(dictor.metadata),
        segmentation:
          dictor.segmentation && dictor.segmentation.ranges.length > 0 ? JSON.stringify(dictor.segmentation) : null,
      };
    });

    const body = {
      recordId,
      dictorsEntryList,
    };
    await BackendService.post('text/segmentation/update', JSON.stringify(body));
  };

  retranslate = async (recordId: number): Promise<void> => {
    return await BackendService.post('text/retranslation', JSON.stringify({ recordId }));
  };
}

export const textService = new TextService();
