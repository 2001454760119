export class AbortControllerService {
  abortControllers: Map<string, AbortController> = new Map();

  control(id: string): AbortController {
    const previousController = this.abortControllers.get(id);
    if (previousController) previousController.abort();

    const newController = new AbortController();
    this.abortControllers.set(id, newController);

    return newController;
  }

  create(id: string) {
    const newController = new AbortController();
    this.abortControllers.set(id, newController);

    return newController;
  }

  abort(id: string) {
    this.abortControllers.get(id)?.abort();
  }

  removeControl(id: string) {
    this.abortControllers.delete(id);
  }
}
