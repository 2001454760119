import { makeStyles } from '@material-ui/core/styles';
import { FlipCameraAndroid, History, Loop, RecordVoiceOver, Settings } from '@material-ui/icons';
import { IToolbarExDescriptorItem, ToolbarEx } from '@uk';
import { observer } from 'mobx-react';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FaChartPie } from 'react-icons/fa';

import RotateLeftIcon from '@material-ui/icons/RotateLeft';

import { gAPP_STORE } from 'app/app-store';
import { EStoreStatus } from 'common/store-status';

import { ERecordStatus, IPhrase, IRecord } from '../types';

import { RecordTextToolbarRebuildModel } from './recrod-text-toolbar-btn-rebuild-model';

import { IconRetranslate } from '@/components/summary/AIicons';
import { TranscriptTranslateToggle } from '@/components/transcriptTranslateToggleButton/TranscriptTranslateToggle';

export const useStyles = makeStyles(() => ({
  iconButtonSquarePants: {
    minWidth: '36px',
    alignItems: 'center',
  },
}));

interface IRecordTextToolbar {
  phrases: IPhrase[];
  selectRow: IRecord | undefined;
  editingMode: boolean;
  needSaveChanges: boolean;
  showSpeakerStatistic: boolean;
  showSettings: boolean;
  showHistory: boolean;
  setShowSettings: (value: boolean) => void;
  setShowHistory: (value: boolean) => void;
}

export const RecordTextToolbar: React.FC<IRecordTextToolbar> = observer(props => {
  const {
    phrases,
    selectRow,
    editingMode,
    needSaveChanges,
    showSpeakerStatistic,
    showSettings,
    showHistory,
    setShowSettings,
    setShowHistory,
  } = props;

  const { t } = useTranslation();

  const classes = useStyles();
  const recordStore = gAPP_STORE.getRecordsStore();
  const soundStore = gAPP_STORE.soundStore;
  const dictorsStore = gAPP_STORE.getDictorsStore();
  const recordTextStore = gAPP_STORE.getRecordTextStore();

  const handleSwitchTranslate = useCallback(() => {
    recordTextStore.changeTranslationMode(!recordTextStore.isTranslation);
    setShowHistory(false);
  }, [recordTextStore, setShowHistory]);

  const handleRebuildModel = useCallback(() => {
    if (selectRow) {
      selectRow.voiceModelStatus = ERecordStatus.PREPROCESSED;
      recordTextStore.update(phrases, dictorsStore.getDictorsWithSegmentation());
    }
  }, [phrases, dictorsStore, recordTextStore, selectRow]);

  const retranslateDisabled =
    selectRow !== undefined
      ? recordTextStore.retranslationStatus === EStoreStatus.LOADING ||
        recordStore.gridStore.isPending ||
        selectRow.status !== ERecordStatus.PROCESSED ||
        (selectRow.transcriptionsCount < 2 && selectRow.translationsCount === 1) ||
        editingMode ||
        needSaveChanges
      : true;

  const toolbarExProps = useMemo((): IToolbarExDescriptorItem[] => {
    const toolbarDescriptor: IToolbarExDescriptorItem[] = [];

    gAPP_STORE.viewTranslate &&
      toolbarDescriptor.push({
        tooltip: 'summary.retranslate',
        type: 'custom',
        disabled:
          recordTextStore.status !== EStoreStatus.SUCCESS ||
          gAPP_STORE.getRecordsStore().gridStore.isPending ||
          editingMode ||
          needSaveChanges,
        component: (
          <TranscriptTranslateToggle isTranslate={recordTextStore.isTranslation} onChange={handleSwitchTranslate} />
        ),
        pl: 1,
      });

    gAPP_STORE.editingASR &&
      recordTextStore.isTranslation &&
      gAPP_STORE.loginStore.user?.isEditor &&
      toolbarDescriptor.push({
        pl: -24,
        tooltip: 'records.retranslate',
        type: 'button',
        buttonClassName: classes.iconButtonSquarePants,
        icon: <IconRetranslate disabled={retranslateDisabled} />,
        disabled: retranslateDisabled,
        onClick: recordTextStore.retranslate,
      });

    toolbarDescriptor.push({
      type: 'custom',
      component: (
        <RecordTextToolbarRebuildModel
          tooltip={t('records.rebuildingModel')}
          buttonClassName={classes.iconButtonSquarePants}
          icon={<RotateLeftIcon />}
          onClick={handleRebuildModel}
        />
      ),
    });

    toolbarDescriptor.push({
      type: 'filler',
    });

    toolbarDescriptor.push({
      type: 'button',
      tooltip: 'Speaker statistics',
      icon: <FaChartPie />,
      pl: 1,
      disabled: !showSpeakerStatistic,
      onClick: () => {
        gAPP_STORE.getDictorsStore().setDictorsStatisticsDialogOpen(true);
      },
    });

    if (recordTextStore.canEditCurrentText) {
      toolbarDescriptor.push({
        type: 'toggle-button',
        icon: soundStore.isCycling ? <FlipCameraAndroid /> : <Loop />,
        tooltip: 'Cycle Mode',
        disabled: false,
        selected: soundStore.isCycling,
        setSelected: soundStore.setIsCycling,
        pl: 1,
      });
    }

    toolbarDescriptor.push({
      type: 'toggle-button',
      icon: <RecordVoiceOver />,
      tooltip: 'records.dictors',
      disabled:
        !dictorsStore.isSegmentationLoaded ||
        !recordTextStore.record ||
        gAPP_STORE.getRecordsStore().gridStore.isPending,
      selected: dictorsStore.isDictorsPanelVisible,
      setSelected: dictorsStore.setIsDictorsPanelVisible,
      pl: 1,
    });

    toolbarDescriptor.push({
      type: 'toggle-button',
      icon: <Settings />,
      disabled:
        editingMode || needSaveChanges || !recordTextStore.record || gAPP_STORE.getRecordsStore().gridStore.isPending,
      tooltip: 'records.transcriptionParameters',
      selected: showSettings,
      setSelected: setShowSettings,
      pl: 1,
    });

    if (
      gAPP_STORE.loginStore.user?.isEditor &&
      // 1. Оба флага равны false — не показывать кнопку.
      !(gAPP_STORE.editingTranslate === false && gAPP_STORE.editingASR === false) &&
      // 2. Показывать для транскрипции, если editingTranslate false и editingASR true,
      !(gAPP_STORE.editingTranslate === false && gAPP_STORE.editingASR === true && recordTextStore.isTranslation) &&
      // 3. Показывать для перевода, если editingTranslate true и editingASR false,
      !(gAPP_STORE.editingTranslate === true && gAPP_STORE.editingASR === false && !recordTextStore.isTranslation)
    ) {
      toolbarDescriptor.push({
        tooltip: 'history',
        type: 'toggle-button',
        icon: <History />,
        disabled:
          editingMode || needSaveChanges || !recordTextStore.record || gAPP_STORE.getRecordsStore().gridStore.isPending,
        selected: showHistory,
        setSelected: setShowHistory,
        pl: 1,
      });
    }

    return toolbarDescriptor;
  }, [
    classes.iconButtonSquarePants,
    dictorsStore.isDictorsPanelVisible,
    dictorsStore.isSegmentationLoaded,
    dictorsStore.setIsDictorsPanelVisible,
    editingMode,
    handleRebuildModel,
    handleSwitchTranslate,
    needSaveChanges,
    recordTextStore.canEditCurrentText,
    recordTextStore.isTranslation,
    recordTextStore.record,
    recordTextStore.retranslate,
    recordTextStore.status,
    retranslateDisabled,
    setShowHistory,
    setShowSettings,
    showHistory,
    showSettings,
    showSpeakerStatistic,
    soundStore.isCycling,
    soundStore.setIsCycling,
    t,
  ]);

  return <ToolbarEx descriptor={toolbarExProps} minWidth={350} overflow={'hidden'} />;
});
