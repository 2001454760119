import axios, { AxiosError, AxiosProgressEvent } from 'axios';
import { action, computed, makeAutoObservable, reaction, runInAction } from 'mobx';
import { v4 as uuidv4 } from 'uuid';

import { alpha } from '@material-ui/core';

import WaveSurferType from 'wavesurfer.js';
import RegionsPlugin, { Region, RegionParams } from 'wavesurfer.js/dist/plugins/regions';
import TimelinePlugin from 'wavesurfer.js/dist/plugins/timeline';

import { ERecordStatus, IRecord, IWordsData } from '../records';
import { ITimecode } from '../summary/types';

import { creatMarkerHtmlElement } from './marker';
import MinimapExtPlugin from './plugins/MinimapExtPlugin';
import RegionsPluginExt from './plugins/RegionPluginExt';
import ZoomToMousePlugin from './plugins/ZoomToMousePlugin';

import appColors from '@/app/app-colors';
import { gAPP_STORE } from '@/app/app-store';

import { gridStorageService } from '@/services/grid-storage/grid-storage-service';
import { IDictor } from '@/types/dictors';

export const PLAYER_HEIGHT = 150;
export const TIMELINE_HEIGHT = 20;
export const RESIZE_DELAY = 100;
export const RELOAD_SEGMENTATION_DELAY = 100;

export interface ICycle {
  begin: number;
  end: number;
}

export class PlayerStore {
  id: string;

  ws: WaveSurferType | undefined;
  regionPlugin: RegionsPlugin | undefined;
  miniMapPlugin: MinimapExtPlugin | undefined;
  zoomPlugin: ZoomToMousePlugin | undefined;
  timeLinePlugin: TimelinePlugin | undefined;
  loadController: AbortController | undefined;

  private _idRec?: number;
  private _loaded = 0;
  private _wsReady = false;
  isFetching = false;
  isDestroyed = true;
  isErrorPlayer = false;
  showRecordInfo = false;
  timelineVisible = true;
  private _cycleRange: ICycle | undefined = undefined;
  private _numberOfChannels = '';

  private _isSummrySegmentation = false;

  constructor(id: string) {
    makeAutoObservable(this, undefined, { autoBind: true });
    this.id = id;
    gAPP_STORE.soundStore.setPlayerStore(this);
    reaction(
      () => gAPP_STORE?.soundStore?.record?.voiceModelStatus,
      () => {
        if (!this.record?.__audioStorageKey) return;
        this.record?.voiceModelStatus === ERecordStatus.PROCESSED &&
          !this?.isFetching &&
          this?.replaceRecordAndLoadPeaks(this.record);
      },
    );

    reaction(
      () => gAPP_STORE?.soundStore?.record?.status,
      () => {
        if (this.record?.status !== ERecordStatus.PROCESSED) return;
        const dictorsStore = gAPP_STORE.getDictorsStore();
        if (!this.record?.correlationId || dictorsStore.segmentationLoading) return;
        gAPP_STORE.getRecordTextStore().loadRecordHistory();
        dictorsStore.resetHide();
      },
    );
    reaction(
      () => gAPP_STORE?.soundStore?.record?.status,
      () => {
        if (!this.record?.__audioStorageKey) return;

        const statusValidation = this.record?.status > 0 && this.record?.status !== ERecordStatus.FAILED;
        const playerLoadingValidation = !this?.isFetching && !this?.loaded;
        playerLoadingValidation && statusValidation && this?.replaceRecordAndLoadPeaks(this.record);
      },
    );
  }

  setCycleRange(value: ICycle | undefined) {
    this._cycleRange = value;
  }

  get channels() {
    return this._numberOfChannels;
  }

  get minPixelPerSec() {
    if (!this.ws) return null;

    return this.ws.options.minPxPerSec;
  }

  get scrollPosInPx() {
    if (!this.ws) return null;

    return this.ws.getScroll();
  }

  @action
  setShowSummrySegmentation(value: boolean) {
    this._isSummrySegmentation = value;
  }

  @computed
  get loaded() {
    return this._loaded;
  }

  @computed
  get wsReady() {
    return this._wsReady;
  }

  get record() {
    return gAPP_STORE.soundStore.record;
  }

  // get plugins() {
  //   return [
  //     // Regions plugin
  //     { plugin: RegionsPlugin.create() },

  //     // TimelinePlugin plugin
  //     this.timelineVisible && {
  //       plugin: TimelinePlugin.create(),
  //       options: {
  //         container: `#TimeLine_${this.id}`,

  //         height: TIMELINE_HEIGHT,
  //         notchPercentHeight: 50,
  //         primaryColor: appColors.grey,

  //         secondaryFontColor: appColors.grey,
  //       },
  //     },
  //   ].filter(Boolean);
  // }

  @computed
  get loading(): boolean {
    return this._loaded < 100;
  }

  setLoaded(loaded: number) {
    this._loaded = loaded;
  }

  setIsFetching(v: boolean) {
    this.isFetching = v;
  }

  setIsDestroyed(isDestroyed: boolean) {
    this.isDestroyed = isDestroyed;
  }

  setErrorPlayer(isErrorPlayer: boolean) {
    this.isErrorPlayer = isErrorPlayer;
  }

  setShowRecordInfo(showRecordInfo: boolean) {
    this.showRecordInfo = showRecordInfo;
  }

  toggleShowRecordInfo() {
    this.showRecordInfo = !this.showRecordInfo;
  }

  handleSeek(event: number) {
    const { soundStore, videoStore } = gAPP_STORE;
    const { setPosition } = soundStore;
    const { updatePositionBySound: updateVideoPositionBySound } = videoStore;

    const newPosition = event;
    setPosition(newPosition);
    updateVideoPositionBySound();
  }

  jumpto(position: number) {
    if (!this.ws) return;
    this.ws.setTime(position);
  }

  async replaceRecordAndLoadPeaks(record: IRecord | undefined): Promise<void> {
    const { id: recordId, status: recordStatus } = record || {};
    if (this.loading && recordId !== this._idRec && this.loadController) {
      this.loadController.abort();
    }

    const { soundStore } = gAPP_STORE;
    this.setLoaded(0);
    this.setErrorPlayer(false);
    this._idRec = recordId;
    this._wsReady = false;

    soundStore.setDuration(0);
    soundStore.setNeedLoadToPlayer(false);
    soundStore.setIsPlaying(false);

    const ws = this.ws;
    if (!ws || !recordId || recordStatus === ERecordStatus.NEW || this.isDestroyed || !record?.__audioStorageKey) {
      return;
    }
    this.setIsFetching(true);
    ws.stop();
    this._numberOfChannels = '';
    ws.empty();

    try {
      let response = null;
      this.loadController = new AbortController();
      if (record?.__waveformStorageKey) {
        const waveformUrl = gridStorageService.getNginxUrl(recordId, record?.__waveformStorageKey); //'tmp/деревня.json'; //;

        response = await axios({
          method: 'get',
          url: waveformUrl,
          responseType: 'json',
          signal: this.loadController.signal,
          onDownloadProgress: (ev: AxiosProgressEvent) => {
            runInAction(() => {
              if (ev.progress && ev.progress * 100 - this.loaded > 1) {
                this.setLoaded(Math.floor(ev.progress * 100));
              }
            });
          },
        });
      }

      ws.setOptions({ fetchParams: { signal: this.loadController.signal } });
      await ws.load(
        gridStorageService.getNginxUrl(recordId, record?.__audioStorageKey),
        response ? response.data.data : undefined,
      );

      runInAction(() => {
        this.setLoaded(100);
      });
    } catch (error) {
      const err = error as AxiosError;
      if (!(err.code && err.code === 'ERR_CANCELED')) {
        console.error(err.message ?? err);
      }
    }
    this.setIsFetching(false);
  }

  private clear() {
    this.setLoaded(0);
    this.setErrorPlayer(true);
    this._idRec = undefined;
    this._wsReady = false;
    this.ws = undefined;

    const { soundStore } = gAPP_STORE;
    soundStore.setDuration(0);
    soundStore.setNeedLoadToPlayer(true);
    soundStore.setIsPlaying(false);
  }

  onRegionClicked(region?: Region, e?: Event) {
    if (e && region && region.id.startsWith('marker') && this.ws) {
      e.stopPropagation();
      this.jumpto(region.start);
    }
  }

  handleWaveSurferMount(waveSurfer: WaveSurferType) {
    this.ws = waveSurfer;

    console.log('WaveSurfer монтирован', this.ws);

    if (this.ws) {
      const { soundStore, videoStore } = gAPP_STORE;
      const { setIsPlaying, setPosition } = soundStore;
      const { setEnded } = videoStore;
      this.setIsDestroyed(false);

      const ws = this.ws;

      // Отладка: регистрация плагинов
      this.miniMapPlugin = ws.registerPlugin(
        MinimapExtPlugin.create({
          height: TIMELINE_HEIGHT,
          waveColor: 'rgba(200, 200, 200, 1)',
          progressColor: 'rgba(200, 200, 200, 0.2)',
          overlayColor: 'rgba(90, 90, 90, 0.6)',
          overlayBorderColor: 'white',
        }),
      );

      this.zoomPlugin = ws.registerPlugin(
        ZoomToMousePlugin.create({
          scale: 0.05,
          maxZoom: 500,
        }),
      );

      this.regionPlugin = ws.registerPlugin(new RegionsPluginExt(undefined, TIMELINE_HEIGHT));

      if (this.regionPlugin) {
        this.regionPlugin.on('region-clicked', (region, event) =>
          runInAction(() => this.onRegionClicked(region, event as Event)),
        );
      }

      if (this.timelineVisible) {
        this.timeLinePlugin = ws.registerPlugin(
          TimelinePlugin.create({
            height: TIMELINE_HEIGHT,
            secondaryLabelOpacity: 0.6,
            style: {
              borderTop: '1px dashed grey',
              borderBottom: '1px dashed grey',
              boxSizing: 'border-box',
              color: appColors.grey,
            },
          }),
        );
      }

      // События `WaveSurfer`
      ws.on('ready', () => {
        this.handleWaveSurferReady();
      });

      ws.on('destroy', () =>
        runInAction(() => {
          this.clear();
        }),
      );

      ws.on('finish', () => {
        setIsPlaying(false);
        setEnded();
      });

      ws.on('pause', () => {
        setIsPlaying(false);
      });

      ws.on('play', () => {
        setIsPlaying(true);
      });

      ws.on('audioprocess', event => {
        if (gAPP_STORE.soundStore.isCycling && this._cycleRange && ws.isPlaying()) {
          if (event < this._cycleRange.begin || event > this._cycleRange.end) {
            this.jumpto(this._cycleRange.begin);
          } else {
            setPosition(event);
          }
        } else {
          setPosition(event);
        }
      });
      ws.on('seeking', position => this.handleSeek(position));
      ws.on('loading', event => this.setLoaded(+String(event)));
      ws.on('redraw', () => runInAction(() => (this._wsReady = true)));

      // ws.on('error', error => {
      //   console.error('Player, error = ', error.toString());
      //   if (!error.toString().includes('user aborted')) {
      //     runInAction(() => {
      //       ws.empty();
      //       this.clear();
      //     });
      //   }
      // });

      if (soundStore.record) {
        this.replaceRecordAndLoadPeaks(soundStore.record);
      }
    }
  }

  // savePlayerRect() {
  //   const { soundStore } = gAPP_STORE;
  //   const { setPlayerRect } = soundStore;

  //   // Запомним размер плеера после загрузки аудио (пауза, что плеер успел отрисоваться)
  //   const waveformElement: HTMLElement | null = document.getElementById('playerRoot');
  //   if (waveformElement) {
  //     const rect = waveformElement.getBoundingClientRect();
  //     if (rect) {
  //       setPlayerRect(rect);
  //     }
  //   }
  // }

  handleWaveSurferReady() {
    const ws = this.ws;
    if (!ws) return;

    if (ws) {
      //if ws empty data will be length = 1
      const dd = ws.getDecodedData();
      const isNotEmpty = dd && dd.length > 1;
      if (isNotEmpty) {
        console.log('WS ready');
        this._numberOfChannels = dd.numberOfChannels === 1 ? 'Mono' : 'Stereo';

        const { soundStore } = gAPP_STORE;
        const { setDuration, setIsPlaying } = soundStore;

        this.setIsDestroyed(false);

        setDuration(ws.getDuration());
        setIsPlaying(ws.isPlaying());

        // setTimeout(() => this.savePlayerRect(), 100);
        if (this._isSummrySegmentation) {
          this.showSummarySegmentation();
        } else {
          this.showDictorsSegmentation();
        }

        this.addMarkers(gAPP_STORE.getRecordTextStore().changedWordsData);
        //this._wsReady = true;
      }
    }
  }

  showDictorsSegmentation() {
    const dictorStore = gAPP_STORE.getDictorsStore();
    const dictors = this.record ? dictorStore.getDictorsWithSegmentationForRecord(this.record.correlationId) : [];
    const dictorsToShowSegmentation = dictors.filter(d => !dictorStore.hideSegmentation.includes(d.id));
    this.addDictorsRegions(dictorsToShowSegmentation);
  }

  showSummarySegmentation() {
    const summaryStore = gAPP_STORE.summaryStore;
    const timecodes = summaryStore.visibleTimecodes;
    if (timecodes.length > 0) {
      this.addTimecodesRegions(timecodes);
      this.jumpto(timecodes[0].begin);
    }
  }

  handlePlayPause(event: Event) {
    event.preventDefault();
    event.stopPropagation();

    const ws = this.ws;

    if (ws) {
      const { soundStore, videoStore } = gAPP_STORE;
      const { setIsPlaying } = soundStore;
      const { togglePlayPause: togglePlayPauseVideo } = videoStore;

      ws.playPause();
      setIsPlaying(ws.isPlaying());

      togglePlayPauseVideo();
    }
  }

  clearMarkers() {
    if (this.regionPlugin) {
      const regions = this.regionPlugin.getRegions();
      this.regionPlugin.clearRegions();
      regions.filter(r => !r.id.startsWith('marker')).forEach(r => this.regionPlugin?.addRegion(r));
    }
  }

  clearRegions() {
    if (this.regionPlugin) {
      const regions = this.regionPlugin.getRegions();
      this.regionPlugin.clearRegions();
      regions.filter(r => r.id.startsWith('marker')).forEach(r => this.regionPlugin?.addRegion(r));
    }
  }

  addPlayerMarker(text: string, begin: number, isPhrase: boolean) {
    this.regionPlugin?.addRegion({
      start: begin,
      resize: false,
      drag: false,
      color: appColors.red /*, position: 'bottom' */,
      content: creatMarkerHtmlElement(text, isPhrase),
      id: `marker_${uuidv4()}`,
    });
  }

  // Добавим маркеры для найденных слов
  addMarkers(currentWordsData: IWordsData | undefined) {
    const ws = this.ws;

    if (ws) {
      this.clearMarkers();
      if (this.regionPlugin && this.regionPlugin.getRegions().length === 0) return;

      if (currentWordsData) {
        const markers = gAPP_STORE.getRecordTextStore().markers;

        markers.words.forEach(mw => this.addPlayerMarker(mw.text, mw.begin, false));

        markers.phrases.forEach(mp => {
          if (mp[0]?.inFilterPhrase) {
            const phraseInTextCase = mp.reduce((ph, w) => (ph += `${w.text}${w.stop_sign ?? ''} `), '');

            this.addPlayerMarker(phraseInTextCase, mp[0].begin, true);
          }
        });
      }
    }
  }

  // Добавим регионы для дикторов (склеим близ лежащие)
  addDictorsRegions(dictors: IDictor[]) {
    const ws = this.ws;

    if (ws) {
      this.clearRegions();

      dictors.forEach(dictor => {
        const ranges = dictor.segmentation ? dictor.segmentation.ranges : [];

        const dictorColor = alpha(dictor.__color ?? 'red', 0.5);

        let currentStart: number | null = null;
        let currentEnd: number | null = null;
        for (let i = 0; i < ranges.length; i += 2) {
          const start = ranges[i];
          const end = ranges[i + 1];

          if (!currentStart) currentStart = start;

          if (currentEnd && end - currentEnd > 0.5) {
            const region: RegionParams = {
              id: uuidv4(),
              start: currentStart,
              end: currentEnd,
              color: dictorColor,
              drag: false,
              resize: false,
              channelIdx: String(dictor.channel).toLowerCase() === 'left' ? 0 : 1,
            };

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (region as any)['loop'] = false;

            this.regionPlugin?.addRegion(region);

            currentStart = start;
            currentEnd = end;
          } else {
            currentEnd = end;
          }
        }

        if (currentEnd && currentStart) {
          const region: RegionParams = {
            id: uuidv4(),
            start: currentStart,
            end: currentEnd,
            color: dictorColor,
            drag: false,
            resize: false,
            channelIdx: String(dictor.channel).toLowerCase() === 'left' ? 0 : 1,
          };

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (region as any)['loop'] = false;

          this.regionPlugin?.addRegion(region);
        }
      });
    }
  }

  addTimecodesRegions(timecodes: ITimecode[]) {
    const ws = this.ws;

    if (ws) {
      this.clearRegions();
      const color = alpha('#FFFFFF', 0.24);
      timecodes.forEach(timecode => {
        const region: RegionParams = {
          id: uuidv4(),
          start: timecode.begin,
          end: timecode.end,
          color: color,
          drag: false,
          resize: false,
        };
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (region as any)['loop'] = false;

        this.regionPlugin?.addRegion(region);
      });
    }
  }
}
