import { INetworkProps } from '../types';

import { IGroup } from '@/components/groups';
import { BackendService } from '@/services/backend-service';
import { checkAndThrowIfAborted } from '@/utils/abortUtils';

export interface IManageUserGroup {
  successAdd: number[];
  failedAdd: number[];
  successDelete: number[];
  failedDelete: number[];
}

class GroupsService {
  getAllGroups = async (networkProps?: INetworkProps) => {
    try {
      const result: IGroup[] = await BackendService.get('groups/all', networkProps?.sendOptions);

      return result.sort((a: IGroup, b: IGroup) => (a.id === b.id ? 0 : a.id - b.id));
    } catch (error) {
      checkAndThrowIfAborted(error);

      console.log('GroupsService::getAllGroups:', { error });
      throw error;
    }
  };

  //===============================================================================
  // administration of Groups
  //===============================================================================
  createGroup = async (name: string, description: string) => {
    try {
      const newGroupData: IGroup = {
        id: -1,
        name,
        description,
        createdDate: new Date().toISOString(),
      };

      await BackendService.put('groups/add', JSON.stringify(newGroupData));
    } catch (error) {
      console.log('GroupsService::deleteGroupById:', { name, description, error });
      throw error;
    }
  };

  deleteGroupById = async (idGroup: number) => {
    try {
      await BackendService.delete(`groups/delete/${idGroup}`);
    } catch (error) {
      console.log('GroupsService::deleteGroupById:', { idGroup, error });
      throw error;
    }
  };

  updateGroup = async (group: IGroup) => {
    try {
      await BackendService.post('groups/update', JSON.stringify(group));
    } catch (error) {
      console.log('GroupsService::updateGroup:', { group, error });
      throw error;
    }
  };

  //===============================================================================
  // Groups for Records
  //===============================================================================
  getRecordCountByGroup = async (idGroup: number) => {
    try {
      return await BackendService.get(`file/by-group/${idGroup}/count`);
    } catch (error) {
      console.log('GroupsService::getRecordCountByGroup:', { idGroup, error });
      //throw new Error('GroupsService');

      return undefined;
    }
  };

  getGroupsByRecordIds = async (recordIds: number[]) => {
    try {
      return await BackendService.post('groups/records', JSON.stringify(recordIds));
    } catch (error) {
      console.log('GroupsService::getGroupsByRecordIds:', { recordIds: recordIds.toString(), error });
      //throw new Error('GroupsService');

      return undefined;
    }
  };

  removeGroupsFromRecord = async (recordId: number, groupIds: number[]) => {
    try {
      await BackendService.put(`groups/record/${recordId}/remove`, JSON.stringify(groupIds));
    } catch (error) {
      console.log('GroupsService::removeGroupsFromRecord:', { recordId, groupIds: groupIds.toString(), error });
      throw error;
    }
  };

  addGroupsToRecord = async (recordId: number, groupIds: number[]) => {
    try {
      await BackendService.put(`groups/record/${recordId}/add`, JSON.stringify(groupIds));
    } catch (error) {
      checkAndThrowIfAborted(error);
      console.log('GroupsService::addGroupsToRecord:', { recordId, groupIds: groupIds.toString(), error });
      throw error;
    }
  };

  //===============================================================================
  // Groups for Users
  //===============================================================================
  getGroupsByUserIds = async (userIds: number[], networkProps?: INetworkProps) => {
    try {
      return await BackendService.post('groups/users', JSON.stringify(userIds), networkProps?.sendOptions);
    } catch (error) {
      checkAndThrowIfAborted(error);
      console.log('GroupsService::addGroupsToRecord:', { userIds: userIds.toString(), error });

      return undefined;
    }
  };

  removeGroupsFromUser = async (userId: number, groupIds: number[]) => {
    try {
      await BackendService.delete(`groups/user/${userId}/remove`, JSON.stringify(groupIds));
    } catch (error) {
      checkAndThrowIfAborted(error);
      console.log('GroupsService::addGroupsToRecord:', { userId, groupIds: groupIds.toString(), error });
      throw error;
    }
  };

  addGroupsToUser = async (userId: number, groupIds: number[]) => {
    try {
      await BackendService.put(`groups/user/${userId}/add`, JSON.stringify(groupIds));
    } catch (error) {
      console.log('GroupsService::addGroupsToUser:', { userId, groupIds: groupIds.toString(), error });
      throw error;
    }
  };

  updateGroupsForUser = async (
    userId: number,
    deletedGroups: number[],
    addedGroups: number[],
  ): Promise<IManageUserGroup> => {
    try {
      const body = {
        add: addedGroups,
        delete: deletedGroups,
      };

      return await BackendService.post(`groups/user/${userId}/change`, JSON.stringify(body));
    } catch (error) {
      console.log('GroupsService::updateGroupsForUser:', {
        userId,
        deletedGroups: deletedGroups.toString(),
        addedGroups: addedGroups.toString(),
        error,
      });
      throw error;
    }
  };
}

export const groupsService = new GroupsService();
