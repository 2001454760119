import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormGroup,
  makeStyles,
  TextField,
} from '@material-ui/core';
import { observer } from 'mobx-react';
import { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import * as yup from 'yup';

import { Controller, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';

import { Autocomplete } from '@material-ui/lab';

import { checkTrim } from '../records/utils/checkTrim';

import { IGroup } from '../groups';

import { CUser, IUser } from './types';

import { userSettingsRestrictions } from '@/common/constants';

import { gAPP_STORE } from '@/app/app-store';
const useStyles = makeStyles(theme => ({
  formControl: {},
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  option: {
    padding: 5,
  },
}));

export type UserUpdate = IUser & { password?: string };

interface IUserDialogBodyProps {
  user?: IUser;
  onChange: (user: IUser) => void;
  setIsValid: (v: boolean) => void;
  isEditing: boolean;
}

export const UserDialogBody: React.FC<IUserDialogBodyProps> = observer(({ user, onChange, setIsValid, isEditing }) => {
  const selectedUser = gAPP_STORE.getUsersStore().gridStore.selectedRow;

  const localState = useRef<UserUpdate>(user ? { ...user } : new CUser());

  const { t } = useTranslation();
  const trimTest = useMemo(() => ({ name: 'checkTrim', test: checkTrim, message: t('users.trimError') }), [t]);
  const formSchema = useMemo(
    () =>
      yup.object({
        name: yup
          .string()
          .required(t('users.requiredField'))
          .test(trimTest)
          .min(userSettingsRestrictions.MIN_NAME_LENGTH, t('users.lengthError'))
          .max(userSettingsRestrictions.MAX_NAME_LENGTH, t('users.lengthError')),
        login: yup
          .string()
          .required(t('users.requiredField'))
          .test(trimTest)
          .test({
            name: 'uniqueLogin',
            message: t('users.mustBeUniqueLogin'),
            test: v =>
              !gAPP_STORE
                .getUsersStore()
                .gridStore.data.map((user: IUser) => user.email.toLocaleUpperCase())
                .includes(v.toLocaleUpperCase()) || selectedUser?.email.toLocaleUpperCase() === v.toLocaleUpperCase(),
          })
          .max(userSettingsRestrictions.MAX_LOGIN_LENGTH, t('users.lengthError'))
          .min(userSettingsRestrictions.MIN_LOGIN_LENGTH, t('users.lengthError')),
        password: isEditing
          ? yup.string().test(function (value) {
              if (!value) return true;
              const max = value?.length > userSettingsRestrictions.MAX_PASSWORD_LENGTH;
              const min = value?.length < userSettingsRestrictions.MIN_PASSWORD_LENGTH;

              if (max) {
                return this.createError({ message: t('users.lengthError') });
              }
              if (min) {
                return this.createError({ message: t('users.lengthError') });
              }

              return true;
            })
          : yup
              .string()
              .required(t('users.requiredField'))
              .test(trimTest)
              .max(userSettingsRestrictions.MAX_PASSWORD_LENGTH, t('users.lengthError'))
              .min(userSettingsRestrictions.MIN_PASSWORD_LENGTH, t('users.lengthError')),
        groups: yup.array(),
        isActive: yup.boolean(),
        IsAdmin: yup.boolean(),
        isSupervisor: yup.boolean(),
        isEditor: yup.boolean(),
      }),
    [isEditing, selectedUser?.email, t, trimTest],
  );

  const classes = useStyles();
  const defaultValues = useMemo(
    () => ({
      name: user?.firstName || '',
      login: user?.email || '',
      password: '',
      groups: user?.groups || [],
      isActive: user?.active || true,
      IsAdmin: user?.isAdmin || false,
      isSupervisor: user?.isSupervisor || false,
      isEditor: user?.isEditor || false,
    }),
    [user?.active, user?.email, user?.firstName, user?.groups, user?.isAdmin, user?.isEditor, user?.isSupervisor],
  );

  const {
    register,
    reset,
    getValues,
    control,
    watch,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(formSchema),
    defaultValues,
    mode: 'onChange',
  });

  useEffect(() => {
    const subscription = watch(values => {
      localState.current.firstName = values?.name || '';
      localState.current.email = values?.login || '';
      localState.current.password = values?.password || '';
      localState.current.groups = values?.groups as IGroup[];
      localState.current.active = values?.isActive || false;
      localState.current.isAdmin = values?.IsAdmin || false;
      localState.current.isSupervisor = values?.isSupervisor || false;
      localState.current.isEditor = values?.isEditor || false;

      onChange(localState.current);
    });

    return () => subscription.unsubscribe();
  }, [onChange, setIsValid, watch]);

  useEffect(() => {
    setIsValid(isValid);
  }, [isValid, setIsValid]);

  return (
    <Box width={600}>
      <FormGroup>
        {/* User name */}
        <FormControl variant="outlined" fullWidth className={classes.formControl}>
          <TextField
            disabled={gAPP_STORE.loginStore.user?.isAD}
            key="name"
            label={t('users.name')}
            variant="outlined"
            fullWidth
            defaultValue={user?.firstName}
            autoComplete="off"
            error={Boolean(errors.name)}
            helperText={errors.name?.message}
            {...register('name', { required: true })}
          />
        </FormControl>
        <Box p={3} />

        {/* User login */}
        <FormControl variant="outlined" fullWidth className={classes.formControl}>
          <TextField
            disabled={gAPP_STORE.loginStore.user?.isAD}
            key="login"
            label={t('users.login')}
            variant="outlined"
            fullWidth
            defaultValue={user?.email}
            autoComplete="login"
            error={Boolean(errors.login)}
            helperText={errors.login?.message}
            {...register('login', { required: true })}
          />
        </FormControl>
        <Box p={1} />

        {/* User password */}
        <FormControl variant="outlined" fullWidth className={classes.formControl}>
          <TextField
            disabled={gAPP_STORE.loginStore.user?.isAD}
            key="password"
            label={t('password')}
            variant="outlined"
            fullWidth
            autoComplete="new-password"
            error={Boolean(errors.password)}
            helperText={errors.password?.message}
            {...register('password', { required: true })}
          />
        </FormControl>
        <Box p={1} />

        <Box p={1} />

        {/* User role and status */}
        <Box display="flex" flexDirection="row" justifyContent="flex-start">
          <Box pl={1}>
            <FormControlLabel
              control={
                <Controller
                  name={'isActive'}
                  control={control}
                  render={({ field: props }) => (
                    <Checkbox
                      {...props}
                      color="primary"
                      checked={props.value}
                      onChange={e => props.onChange(e.target.checked)}
                    />
                  )}
                />
              }
              label={t('users.active')}
            />
          </Box>
        </Box>
        <Box p={1} />

        <Box display="flex" flexDirection="row" justifyContent="flex-start">
          <Box pl={1}>
            <FormControlLabel
              control={
                <Controller
                  name={'IsAdmin'}
                  disabled={gAPP_STORE.loginStore.user?.isAD}
                  control={control}
                  render={({ field: props }) => (
                    <Checkbox
                      color="primary"
                      {...props}
                      checked={props.value}
                      onChange={e => props.onChange(e.target.checked)}
                    />
                  )}
                />
              }
              label={t('users.admin')}
            />
          </Box>
          <Box p={1} />

          <Box pl={1}>
            <FormControlLabel
              control={
                <Controller
                  name={'isSupervisor'}
                  control={control}
                  render={({ field: props }) => (
                    <Checkbox
                      color="primary"
                      {...props}
                      checked={props.value}
                      onChange={e => props.onChange(e.target.checked)}
                    />
                  )}
                />
              }
              label={t('users.supervisor')}
            />
          </Box>

          <Box pl={1}>
            <FormControlLabel
              control={
                <Controller
                  name={'isEditor'}
                  control={control}
                  render={({ field: props }) => (
                    <Checkbox
                      color="primary"
                      {...props}
                      checked={props.value}
                      onChange={e => props.onChange(e.target.checked)}
                    />
                  )}
                />
              }
              label={t('users.editor')}
            />
          </Box>
        </Box>

        <Box p={2} />

        {/* Groups */}
        {gAPP_STORE.groups && (
          <FormControl variant="outlined" fullWidth className={classes.formControl}>
            <Controller
              name="groups"
              control={control}
              defaultValue={defaultValues.groups}
              render={({ field }) => {
                const value = field.value;

                return (
                  <Autocomplete
                    value={value}
                    multiple
                    options={gAPP_STORE.getGroupsStore().data.groups}
                    noOptionsText={t('uploadForm.noGroups')}
                    getOptionLabel={option => option?.name || ''}
                    onChange={(_event, value) => field.onChange(value)}
                    getOptionSelected={(option, value) => option?.id === value?.id}
                    filterSelectedOptions
                    renderInput={params => <TextField {...params} label={t('users.groups')} variant="outlined" />}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip color="primary" label={option?.name} {...getTagProps({ index })} />
                      ))
                    }
                  />
                );
              }}
            ></Controller>
          </FormControl>
        )}

        <Box p={1} />
      </FormGroup>
    </Box>
  );
});
