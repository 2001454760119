import { makeAutoObservable, observable, runInAction } from 'mobx';

import { DataGridStore, IDataGridStoreData } from '@uk';

import { IStoreWithLoader } from '../settings/types';

import { AbortControllerService } from './../../services/abort-controller-service';

import { IUser } from './types';
import { usersGridColumns } from './users-grid-columns';

import { gAPP_STORE } from '@/app/app-store';
import { userService } from '@/services/user/user-service';
import { checkIfAborted } from '@/utils/abortUtils';

export class UsersStore implements IStoreWithLoader {
  data: { users: IUser[] } = { users: [] };
  searchQuery = '';

  gridStore: DataGridStore<IUser>;
  gridCols = usersGridColumns;

  abortControllerService = new AbortControllerService();

  dataProviderLoading = { state: false };

  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });

    const dataProvider = async (): Promise<IDataGridStoreData<IUser>> => {
      await this.getAllImpl();

      return { data: this.data.users, total: this.data.users.length, hasNextPage: false };
    };

    this.gridStore = new DataGridStore<IUser>('UserSettings', this.gridCols, { dataProvider });
  }

  isLoading(): boolean {
    return this.dataProviderLoading.state;
  }

  getAll = async () => {
    await this.gridStore.reload();
  };

  private getAllImpl = async () => {
    const localCopy = observable({ state: true });

    try {
      const abortController = this.abortControllerService.control('getAllImpl');
      const networkProps = { sendOptions: { abort: abortController } };
      this.dataProviderLoading = localCopy;
      const users: IUser[] = await userService.getAllUsers(networkProps);

      if (users.length > 0) {
        const groups = await gAPP_STORE.getGroupsStore().getUserGroupsNoError(
          users.map(user => user.id),
          networkProps,
        );
        if (groups) {
          const userGroupsIds = (gAPP_STORE.loginStore.user?.groups || []).map(g => g.id);

          users.forEach(user => {
            user.groups = groups[user.id];
            // Если текущий пользователь не супервизор, то оставляем только те группы, в которых он состоит
            if (!gAPP_STORE.loginStore.user?.isSupervisor && !gAPP_STORE.loginStore.user?.isAdmin) {
              user.groups = user.groups?.filter(g => userGroupsIds.includes(g.id));
            }
          });
        }
      }

      runInAction(() => {
        this.data.users = users;
      });
    } catch (error) {
      if (checkIfAborted(error)) return;
      console.error(error);
    } finally {
      localCopy.state = false;
      if (this.dataProviderLoading === localCopy) {
        this.abortControllerService.removeControl('getAllImpl');
      }
    }
  };

  create = async (user: IUser, password: string): Promise<IUser | undefined> => {
    return await userService.createUser(user, password);
  };

  update = async (user: IUser, oldValue: IUser) => {
    await userService.updateUser(user);
  };

  updatePassword = async (user: IUser, password: string) => {
    await userService.updatePassword(user.id, password);
  };

  delete = async (user: IUser) => {
    await userService.deleteUser(user.id);
  };

  getUserNameById = (id: number) => {
    const user = this.data.users.find(u => u.id === id);

    return user ? `${user.firstName}` : undefined;
  };
}
