import i18n from 'i18next';
import { toast } from 'react-toastify';

import { IBackUser, IUser } from '@/components/users/types';
import { userBackToFront, userFrontToBack } from '@/components/users/user-utils';
import { BackendService } from '@/services/backend-service';
import { BackendError, HandleErrorTranslatedMessage, INetworkProps } from '@/services/types';
import { checkAndThrowIfAborted } from '@/utils/abortUtils';

interface IAnswerViaJWT {
  hashTag5: boolean;
  userEntry: IBackUser;
}

class CUserService {
  generateJWTToken = async (): Promise<string> => {
    return await BackendService.get('user/jwt/generate');
  };

  getUserDataViaJWT = async (token: string): Promise<IUser> => {
    const body = JSON.stringify({ token });

    const data: IAnswerViaJWT = await BackendService.post('user/jwt/auth', body);
    if (data.userEntry) {
      return userBackToFront({ ...data.userEntry, hashTag5: data.hashTag5 });
    }
    throw new Error('no user');
  };

  getUserDataViaAuth = async (username: string, code: string, password: string): Promise<IUser> => {
    const body = JSON.stringify({
      username,
      password,
      code,
    });
    const options = {
      sessionId: '',
    };

    const data: IAnswerViaJWT = await BackendService.post('auth', body, options);
    if (data.userEntry) {
      return userBackToFront({ ...data.userEntry, hashTag5: data.hashTag5 });
    }
    throw new Error('no user');
  };

  getCurrentUserData = async (): Promise<IUser> => {
    const backUser = await BackendService.get('user/my-info');

    return userBackToFront(backUser);
  };

  getAllUsers = async (networkProps?: INetworkProps): Promise<IUser[]> => {
    try {
      const data: IBackUser[] = await BackendService.get('user', networkProps?.sendOptions);
      data.sort((a: IBackUser, b: IBackUser) => (a.id === b.id ? 0 : a.id - b.id));

      return data.map(user => userBackToFront(user));
    } catch (e) {
      checkAndThrowIfAborted(e);

      if (e instanceof BackendError && e.status === 401) {
        toast.error(i18n.t(e.statusMessage));
      } else {
        toast.error(i18n.t('BadRequest'));
      }

      return [];
    }
  };

  createUser = async (user: IUser, password: string): Promise<IUser | undefined> => {
    try {
      const newUserData = { ...userFrontToBack(user), password };

      const data: IBackUser = await BackendService.post('user', JSON.stringify(newUserData));

      return userBackToFront(data);
    } catch (error) {
      if (error instanceof BackendError) {
        if (error.errorInfo?.description.includes('already exists') || (!error.errorInfo && error.status === 500)) {
          toast.error(i18n.t('unicLogin'));
        } else if (error.errorInfo.reasonCode) {
          toast.error(HandleErrorTranslatedMessage(error));
        } else {
          toast.error(i18n.t('BadRequest'));
        }
      } else {
        toast.error(i18n.t('BadRequest'));
      }

      return undefined;
    }
  };

  deleteUser = async (idUser: number): Promise<void> => {
    try {
      await BackendService.delete(`user/delete/${idUser}`);
    } catch (error) {
      if (error instanceof BackendError) {
        if (error.errorInfo.reasonCode) {
          toast.error(HandleErrorTranslatedMessage(error));
        } else {
          toast.error(i18n.t('BadRequest'));
        }
      } else {
        toast.error(i18n.t('BadRequest'));
      }
    }
  };

  updateUser = async (user: IUser): Promise<void> => {
    try {
      await BackendService.put('user', JSON.stringify(userFrontToBack(user)));
    } catch (error) {
      if (error instanceof BackendError) {
        if (error.errorInfo.reasonCode) {
          toast.error(HandleErrorTranslatedMessage(error));
        } else {
          toast.error(i18n.t('BadRequest'));
        }
      } else {
        toast.error(i18n.t('BadRequest'));
      }
    }
  };

  updatePassword = async (idUser: number, password: string) => {
    try {
      const options = {
        textContent: true,
      };
      await BackendService.put(`user/${idUser}`, password, options);
    } catch (error) {
      toast.error(i18n.t('BadRequest'));
      throw error;
    }
  };
}

export const userService = new CUserService();
