import { makeAutoObservable } from 'mobx';

import { ISettingsTab } from './types';

import { gAPP_STORE } from '@/app/app-store';

export class SettingsPageStore {
  tabs: Record<string, ISettingsTab> = {
    userSettings: {
      loader: gAPP_STORE.getUsersStore().getAll,
      tabStore: gAPP_STORE.getUsersStore(),
    },
    groupSettings: {
      loader: gAPP_STORE.getGroupsStore().getAll,
      tabStore: gAPP_STORE.getGroupsStore(),
    },
    templateSettings: {
      loader: gAPP_STORE.getTemplateStore().getAll,
      tabStore: gAPP_STORE.getTemplateStore(),
    },
    languageSettings: {
      loader: gAPP_STORE.getLanguagesStore().getAll,
      tabStore: gAPP_STORE.getLanguagesStore(),
    },
    autoTranslate: {
      loader: gAPP_STORE.getWordDictionaryStore().getAll,
      tabStore: gAPP_STORE.getWordDictionaryStore(),
    },
    eventLog: {
      loader: async () => {
        await gAPP_STORE.getEventLogStore().init();
        await gAPP_STORE.getEventLogStore().reload();
      },
      tabStore: gAPP_STORE.getEventLogStore(),
    },
  };

  currentTab?: ISettingsTab = undefined;

  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  setCurrentTab(tabId: string) {
    const foundTab = this.tabs[tabId];
    if (foundTab) this.currentTab = foundTab;
  }

  async loadTab(tabId: string) {
    const tabLoader = this.tabs[tabId]?.loader;
    if (tabLoader) {
      await tabLoader();
    }
  }
}
