import { FC, MouseEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';

import { Box, Grow, Menu, MenuItem } from '@material-ui/core';

import { useTranslation } from 'react-i18next';

import { toast } from 'react-toastify';

import { gAPP_STORE } from '@/app/app-store';
import { IDictor } from '@/types/dictors';
import { DictorChipLight } from '@/components/dictors/DictorChipLight';

interface ISelectButtonProps {
  dictor: IDictor;
  onChangeDictor: (dictor: IDictor) => void;
  onClose?: () => void;
}

const BUTTON_SIZE = 40;

const SelectButton: FC<ISelectButtonProps> = ({ dictor, onChangeDictor, onClose }) => {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [position, setPosition] = useState({ left: false, top: false });
  const selectBtnRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  const dictorsStore = gAPP_STORE.getDictorsStore();

  const dictorsToShow = useMemo(
    () => dictorsStore.getDictorsForMenu(dictor.id, dictor.channel),
    [dictor.channel, dictor.id, dictorsStore],
  );

  const hButtonClick = useCallback((event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  }, []);

  const onCloseImpl = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      event.stopPropagation();
      setAnchorEl(null);
      onClose && onClose();
    },
    [onClose],
  );

  useEffect(() => {
    if (selectBtnRef.current) {
      const buttonPosition = selectBtnRef.current?.getBoundingClientRect();
      const top = buttonPosition?.bottom + BUTTON_SIZE >= window.innerHeight;
      const left = buttonPosition.right >= window.innerWidth;

      setPosition({ top, left });
    }
  }, []);

  useEffect(() => {
    if (dictorsToShow.length <= 0) {
      toast.warn(`${t('dictorEdit.noDictorsToChange')}`, { toastId: 222 });
    }
  }, [t, dictorsToShow]);

  if (dictorsToShow.length <= 0) return null;

  return (
    <div
      data-autotest={'buttonSelectDictor'}
      onClick={e => hButtonClick(e)}
      ref={selectBtnRef}
      style={{
        outline: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: `${BUTTON_SIZE}px`,
        height: `${BUTTON_SIZE}px`,
        background: Boolean(anchorEl) ? '#fafafa' : '#e1e1e1',
        borderRadius: '5px',
        border: '1px solid gray',
        position: 'absolute',
        right: `${position.left ? '-8px' : '-48px'}`,
        bottom: `${position.top ? '22px' : position.left ? '-48px' : '-22px'}`,
        boxShadow: '3px 3px 5px rgba(0,0,0,0.3)',
        cursor: 'pointer',
        zIndex: 999,
      }}
      onMouseDown={e => {
        e.stopPropagation();
      }}
      onMouseMove={e => {
        e.stopPropagation();
      }}
    >
      <RecordVoiceOverIcon style={{ height: '30px', width: '30px', pointerEvents: 'none' }} />

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={onCloseImpl}
        TransitionComponent={Grow}
        transformOrigin={{
          vertical: -50,
          horizontal: -10,
        }}
        disableAutoFocus={true}
        disableEnforceFocus={true}
      >
        <div data-autotest={'menuDictors'}>
          {dictorsToShow.map((d, ind) => {
            return (
              <MenuItem key={ind}>
                <Box
                  onClick={e => {
                    e.stopPropagation();
                    setAnchorEl(null);
                    onChangeDictor(d);
                  }}
                >
                  <DictorChipLight key={ind} dictor={d} />
                </Box>
              </MenuItem>
            );
          })}
        </div>
      </Menu>
    </div>
  );
};

export default SelectButton;
