import { toast } from 'react-toastify';
import { BackendService } from 'services';

import i18n from 'i18next';

import { IEventDescription, IEventLogEntry } from './types';

import { INetworkProps } from '@/services/types';
import { IDictor } from '@/types/dictors';
import { checkAndThrowIfAborted } from '@/utils/abortUtils';

export enum TInformDictorCard {
  create,
  search,
  rename,
  delete,
  errorCreate,
  errorSearch,
}

export class EventLogService {
  public static async getAll(): Promise<IEventLogEntry[]> {
    try {
      const result = await BackendService.get('event_log/all');

      return JSON.parse(result);
    } catch (error) {
      toast.error(i18n.t('BadRequest'));

      throw error;
    }
  }

  public static async getEventDecriptions(networkProps?: INetworkProps): Promise<IEventDescription[]> {
    try {
      return await BackendService.get('event_log/action_messages', networkProps?.sendOptions);
    } catch (error) {
      checkAndThrowIfAborted(error);

      toast.error(i18n.t('BadRequest'));

      return [];
    }
  }

  private static infoUrls: string[] = [
    'event_log/dictor-card-create/inform',
    'event_log/dictor-card-search/inform',
    'event_log/dictor-card-name-update/inform',
    'event_log/dictor-card-delete/inform',
    'event_log/dictor-card-create-error/inform',
    'event_log/dictor-card-search-error/inform',
  ];
  public static async informDictorCard(dictor: IDictor | undefined, action: TInformDictorCard): Promise<void> {
    if (dictor === undefined) return;
    const url = this.infoUrls[action as number];
    try {
      const body = JSON.stringify({
        recordId: dictor?.recordId ?? -1,
        name: dictor?.name ?? '',
        surname: dictor?.surname ?? '',
      });
      await BackendService.post(url, body);
    } catch (error) {
      console.warn(`Server ${url} error: ${JSON.stringify(error)}`);
    }
  }

  public static async informUploadFilesLimitReached(): Promise<void> {
    const url = 'event_log/upload-files-limit-reached/inform';
    try {
      await BackendService.post(url, JSON.stringify({}));
    } catch (error) {
      console.warn(`Server ${url} error: ${JSON.stringify(error)}`);
    }
  }
}
