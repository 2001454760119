import Docxtemplater from 'docxtemplater';
import i18next from 'i18next';
import { makeAutoObservable, runInAction, transaction } from 'mobx';
import { toast } from 'react-toastify';

import { gAPP_STORE } from 'app/app-store';
import { EStoreStatus } from 'common/store-status';
import { BackendService } from 'services';

import { UNKNOWN_SPEAKER } from '../dictors/const';
import { IFilterPhrase } from '../filter/types';
import { EDirection, ESpecialLanguageCode } from '../languages/i-language';

import { createExportReport } from './docxUtils';
import { correctIm, isRtlLanguage, sortHistoryWordsData, wordsToСhannelText } from './text/record-text-utils';
import {
  IDocxData,
  IFiltered,
  ILabelReport,
  IPhrase,
  IRecord,
  ITemplateHistory,
  IToFileDialogResult,
  IWord,
  IWordsData,
} from './types';

import { dstr2str } from '@/common/utils';
import { IGroupReport } from '@/components/groups/types';
import { ITopicReport } from '@/components/wordDictionary/WordDictionary.types';
import i18n from '@/i18n';
import { exportFileService } from '@/services/export-file-service';
import { textService } from '@/services/text/text-service';
import { ExportFileExtension } from '@/types/common';
import { IDictor } from '@/types/dictors';
import { checkAndThrowIfAborted } from '@/utils/abortUtils';
import { getIndicesOf } from '@/utils/stringUtils';

interface ITextStoreState {
  isTranslation: boolean;
}

interface IMarkers {
  words: IWord[];
  phrases: IWord[][];
}

interface IAppliedFilter {
  filterWords: string[];
  filterPhrase: IFilterPhrase[];
}
export class RecordTextStore {
  // выбранная запись
  record?: IRecord;
  originRecord?: IRecord;
  // статус загрузки данных
  status: EStoreStatus = EStoreStatus.EMPTY;
  // статус отправки запроса на переперевод
  retranslationStatus: EStoreStatus = EStoreStatus.EMPTY;
  // флаг - что показываем: транскрипцию или перевод
  isTranslation = false;
  // все ревизии правок транскрипции или перевода, зависит от флага isTranslation
  historyWordsData: IWordsData[] = [];
  _changedWordsData?: IWordsData;
  phrasePauseSec = 0;
  sentencePauseSec = 1;
  useSentencePauseSelector = true;
  wordProbability = 30;
  // Выбранная в истории ревизия транскрипции
  selectedTranscriptionId?: number = undefined;
  // Выбранная в истории ревизия перевода
  selectedTranslationId?: number = undefined;
  editedPhraseIndex?: number = undefined;
  private abortController?: AbortController;
  _applyedFilter: IAppliedFilter | undefined = undefined;

  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
    this.restoreState();
  }

  abortIfActive() {
    if (this.abortController) {
      this.abortController.abort();
      this.abortController = undefined;
    }
  }
  private saveState = () => {
    const state: ITextStoreState = {
      isTranslation: this.isTranslation,
    };

    localStorage.setItem(`${gAPP_STORE.loginStore.user?.id}_TextStoreState`, JSON.stringify(state));
  };

  setEditedPhraseIndex(index?: number) {
    this.editedPhraseIndex = undefined;
    if (this.canEditCurrentText) {
      this.editedPhraseIndex = index;
    }
  }

  restoreState = () => {
    const stateStr = localStorage.getItem(`${gAPP_STORE.loginStore.user?.id}_TextStoreState`) || '{}';
    const state: ITextStoreState = JSON.parse(stateStr);

    this.setTranslation(state.isTranslation && gAPP_STORE.viewTranslate ? state.isTranslation : false);
  };

  changeTranslationMode = async (mode: boolean) => {
    this.setTranslation(mode);
    if (!mode) {
      //clear translation selection when switch to original view
      this.resetSelectedTranslationId();
    }
    this.saveState();

    await this.loadRecordHistory(true);
  };

  /**
   * Загрузить транскрипции или переводы
   */
  loadRecordHistory = async (isLastActive?: boolean) => {
    runInAction(() => {
      this._applyedFilter = undefined;
      this._changedWordsData = undefined;
    });
    if (!this.record) {
      return;
    }

    try {
      this.setStatus(EStoreStatus.LOADING);

      this.abortController = new AbortController();
      const historyData = await textService.getHistory(
        this.record.correlationId,
        this.isTranslation && gAPP_STORE.viewTranslate,
        this.abortController ?? undefined,
      );

      runInAction(() => {
        this.abortController = undefined;

        const sortedHistory: IWordsData[] = sortHistoryWordsData(historyData);
        const histLength = sortedHistory.length;

        transaction(() => {
          if (this.isTranslation) {
            !(this.selectedTranslationId && !isLastActive) &&
              this.setSelectedTranslationId(sortedHistory[histLength - 1].id);
          } else {
            !(this.selectedTranscriptionId && !isLastActive) &&
              this.setSelectedTranscriptionId(sortedHistory[histLength - 1].id);
          }
          this.setHistoryWordsData(sortedHistory);
          this._applyedFilter = this.markFilteredInWordData(this.currentWordsData);
          this.setStatus(EStoreStatus.SUCCESS);
        });
      });
    } catch (error) {
      checkAndThrowIfAborted(error);

      console.error('TextStore, setRecord(), error = ', error);
      this.clear(EStoreStatus.ERROR);
    }
  };

  /**
   *
   * @param doc Шаблон результурующего документа
   * @param record выбранная запсь
   * @param dialogResult параметры сохранения отчета
   * @param fileExtension формат сохранения отчета
   */
  createExportFile = async (
    doc: Docxtemplater,
    record: IRecord,
    dialogResult: IToFileDialogResult,
    fileExtension: ExportFileExtension,
  ): Promise<void> => {
    //fix 9394
    const dictorsStore = gAPP_STORE.getDictorsStore();
    await dictorsStore.reloadRecordDictors(record);
    const { transcription, translation, translationAuto, historyTranscription, historyTranslation } =
      await this.getWordsToExport(record, true);
    const speakers = record ? dictorsStore.getDictorsWithSegmentationForRecord(record.correlationId) : [];
    const defaultSpeaker = gAPP_STORE.unknownDictorInReport ?? i18n.t(UNKNOWN_SPEAKER) ?? '';
    const isResolvedRtlLanguage = isRtlLanguage(record.languageResolved);
    const isTranslateRtlLanguage = isRtlLanguage(record.translateLanguage);
    const summaryToExport = await gAPP_STORE.summaryStore.prepareForReport(
      record,
      speakers,
      defaultSpeaker,
      isResolvedRtlLanguage,
      isTranslateRtlLanguage,
    );
    const transcriptionLanguage = i18n.t(record.languageResolved, { ns: 'lang' });
    const groups: IGroupReport[] = record.groups
      ? record.groups.map((g, index) => ({ index: index + 1, name: g.name }))
      : [];
    const labels: ILabelReport[] = record.labelLists
      ? record.labelLists.map((lbl, index) => ({ index: index + 1, name: lbl.name, comment: lbl.comment ?? '' }))
      : [];
    const topics: ITopicReport[] = record.topics
      ? record.topics.map((t, index) => ({
          index: index + 1,
          name: t.name,
          threshold: t.kwsThreshold ? `${Math.floor(t.kwsThreshold)}%` : '',
        }))
      : [];

    createExportReport({
      doc,
      record,
      transcriptionLanguage,
      groups,
      labels,
      topics,
      summaryToExport,
      speakers,
      defaultSpeaker,
      transcription,
      translation,
      translationAuto,
      historyTranscription,
      historyTranslation,
      isAutoReport: false,
      isResolvedRtlLanguage: isRtlLanguage(record.languageResolved),
      isTranslateRtlLanguage: isRtlLanguage(record.translateLanguage),
      showTime: dialogResult.showTime,
      phrasePauseSec: this.phrasePauseSec,
      sentencePause: gAPP_STORE.getRecordTextStore().useSentencePauseSelector ? this.sentencePauseSec : undefined,
      userName: gAPP_STORE.loginStore.user?.firstName || gAPP_STORE.translationStore.translate('Unknown user'),
      filename: dialogResult.filename,
      fileExtension,
      fnSave: (blob: Blob, fileName: string, extension?: ExportFileExtension) =>
        exportFileService.saveAs({ blob, fileName, extension: extension ?? 'pdf' }),
    });
  };

  clear = (status = EStoreStatus.EMPTY) => {
    this.resetChangedWordsData();
    this.setHistoryWordsData([]);
    this.setStatus(status);
    this.setRetranslationStatus(status);
    this.setSelectedTranslationId(undefined);
    this.setEditedPhraseIndex(undefined);
    this.setSelectedTranscriptionId(undefined);
    this.setRecord(undefined);
  };

  resetChangedWordsData() {
    if (this._changedWordsData) {
      const historyId = this._changedWordsData.id;
      this._changedWordsData = undefined;
      this.selectHistoryItem(historyId);
    }
  }

  setChangedWordsData(value: IWordsData) {
    //!!! не принимает undefined
    // undefined суть сброс
    // сбрасываем только внутри методов стора
    runInAction(() => {
      this._applyedFilter = this.markFilteredInWordData(value);
      this._changedWordsData = value;
    });
  }

  get changedWordsData() {
    return this._changedWordsData ?? this.currentWordsData;
  }

  private async makeSegmentationFromPhrase(id: number, phrases: IPhrase[], dictors: IDictor[]) {
    const dictorRanges = new Map<number, number[]>();
    phrases.forEach(phrase => {
      if (phrase.dictor && phrase.tokens.length > 0) {
        const dictorId = phrase.dictor.id;
        const range = dictorRanges.get(dictorId);
        const begin = phrase.tokens[0].begin;
        const end = phrase.tokens[phrase.tokens.length - 1].end;
        if (range) {
          range.push(begin);
          range.push(end);
        } else {
          dictorRanges.set(dictorId, [begin, end]);
        }
      }
    });
    const dictorsWithNewSegmentation: IDictor[] = dictors.map(dictor => {
      const segm = { ...dictor.segmentation };
      segm.ranges = dictorRanges.get(dictor.id) ?? [];

      return {
        ...dictor,
        segmentation: segm,
      };
    });

    await textService.updateSegmentation(id, dictorsWithNewSegmentation);
  }

  update = async (phrases: IPhrase[], dictors?: IDictor[]) => {
    const channelWords = this._changedWordsData?.words;
    try {
      if (this.record && channelWords) {
        if (dictors && !this.isTranslation) {
          this.makeSegmentationFromPhrase(this.record.id, phrases, dictors);
        }
        const data = await textService.updateText(
          this.record.correlationId,
          this.isTranslation && gAPP_STORE.viewTranslate,
          channelWords,
        );

        if (data?.id) {
          if (this.isTranslation) {
            this.record.translationId = data.id;
            await this.setRecord(this.record, this.selectedTranscriptionId, data.id);
          } else {
            this.record.transcriptionId = data.id;
            await this.setRecord(this.record, data.id, this.selectedTranslationId);
            await gAPP_STORE.getDictorsStore().reloadRecordDictors(this.record);
          }
        }
      }
    } catch (error) {
      this.setStatus(EStoreStatus.ERROR);
    } finally {
      runInAction(() => {
        this._changedWordsData = undefined;
      });
    }
  };

  retranslate = async () => {
    if (this.record) {
      try {
        this.setRetranslationStatus(EStoreStatus.LOADING);
        await textService.retranslate(this.record.id);
        this.setRetranslationStatus(EStoreStatus.SUCCESS);
        toast.info(gAPP_STORE.translationStore.translate('records.retranslateSuccess'));
        if (!this.isTranslation) {
          this.resetSelectedTranslationId();
        }
      } catch {
        this.setRetranslationStatus(EStoreStatus.ERROR);
        toast.error(gAPP_STORE.translationStore.translate('records.retranslateError'));
      }
    }
  };

  get appliedTextFilter() {
    return this._applyedFilter;
  }

  selectHistoryItem = (id: number) => {
    const ind = this.historyWordsData.findIndex(d => d.id === id);
    if (ind < 0) {
      console.error(
        'changeHistoryItem ',
        id,
        'not exists for ',
        this.isTranslation ? 'translation.' : 'trnascription.',
      );

      return;
    }
    transaction(() => {
      if (this.isTranslation) {
        this.setSelectedTranslationId(id);
      } else {
        this.setSelectedTranscriptionId(id);
      }
      this._applyedFilter = this.markFilteredInWordData(this.historyWordsData[ind]);
    });
  };

  getHistoryWordsDataById = (id: number | undefined): IWordsData | undefined => {
    return this.historyWordsData.find(d => d.id === id);
  };

  get isTwoChanels(): boolean {
    const len = this.currentWordsData?.words?.length;

    return !!len && len > 1;
  }

  async setRecord(
    value: IRecord | undefined,
    transcriptionId: number | undefined = undefined,
    translationId: number | undefined = undefined,
  ) {
    this.abortIfActive();
    if (this.originRecord === undefined || value?.id !== this.originRecord?.id) {
      this.originRecord = value;
    }
    transaction(() => {
      this.record = value;
      this.setSelectedTranscriptionId(transcriptionId);
      this.setSelectedTranslationId(translationId);
    });
    await this.loadRecordHistory();
  }

  setStatus(value: EStoreStatus) {
    this.status = value;
  }

  setRetranslationStatus(value: EStoreStatus) {
    this.retranslationStatus = value;
  }

  setPhrasePauseSec(pauseSec: number) {
    this.phrasePauseSec = pauseSec;
  }

  setSentencePauseSec(pauseSec: number) {
    this.sentencePauseSec = pauseSec;
  }

  setSentencePauseSelector(isUse: boolean) {
    this.useSentencePauseSelector = isUse;
  }

  setWordProbability(wordProbability: number) {
    this.wordProbability = wordProbability;
  }

  setTranslation(value: boolean) {
    this.isTranslation = value;
  }

  setHistoryWordsData(value: IWordsData[]) {
    this.historyWordsData = value;
  }

  setSelectedTranscriptionId(value?: number) {
    if (this.selectedTranscriptionId !== value) {
      this._changedWordsData = undefined;
      this.selectedTranscriptionId = value;
    }
  }

  setSelectedTranslationId(value?: number) {
    if (this.selectedTranslationId !== value) {
      this._changedWordsData = undefined;
      this.selectedTranslationId = value;
    }
  }

  resetSelectedTranslationId() {
    this.setSelectedTranslationId();
  }

  getDownLoadInform = async (
    fileFormat: string,
    recordName: string | undefined,
    template: string,
    fileName: string,
  ) => {
    const urlParams = {
      format: fileFormat,
      filename: fileName,
      recordName,
      templateName: template,
    };

    await BackendService.post('text/download/inform', JSON.stringify(urlParams));
  };

  getDownLoadTemplate = async (template: string) => {
    const urlParams = {
      templateName: template,
    };

    await BackendService.post('template/download/inform', JSON.stringify(urlParams));
  };

  // идентификатор текущей ревизии текста
  get currentHistoryId() {
    if (this.isTranslation) {
      return this.selectedTranslationId;
    } else {
      return this.selectedTranscriptionId;
    }
  }

  // текущая ревизия текста (отображаемые слова)
  get currentWordsData(): IWordsData | undefined {
    return this.getHistoryWordsDataById(this.currentHistoryId);
  }

  // доступен ли режим правки для выбранного текста
  get canEditCurrentText() {
    if (!gAPP_STORE.loginStore.user?.isEditor) return false;

    return (gAPP_STORE.editingASR && !this.isTranslation) || (gAPP_STORE.editingTranslate && this.isTranslation);
  }

  // направление текущего отображаемого текста
  get currentTextDirection(): EDirection {
    const recordLanguage = this.isTranslation ? this.record?.translateLanguage : this.record?.languageResolved;
    const isRTL = gAPP_STORE.isRtlLanguage(recordLanguage);

    return isRTL ? EDirection.RTL : EDirection.LTR;
  }

  private async getHistoryToExport(
    correlationId: string,
    isTranslate: boolean,
    autoName: string,
  ): Promise<ITemplateHistory[]> {
    const historyData = await textService.getHistory(correlationId, isTranslate);

    return sortHistoryWordsData(historyData, true).map((historyItem, index) => {
      return {
        index: index + 1,
        itemCreatedDate: dstr2str(historyItem.createdDate),
        author: historyItem.creatorId
          ? gAPP_STORE.getUsersStore().getUserNameById(+historyItem.creatorId) ?? ''
          : i18next.t(autoName),
      };
    });
  }

  /**
   * Получить транскрипцию, автоматический и актуальный переводы транскрипции.
   * @param record
   * @param useCurrentTranscription
   */
  private async getWordsToExport(record: IRecord, useCurrentTranscription: boolean): Promise<IDocxData> {
    let transcriptionData: IWordsData | undefined;
    let translationAutoData: IWordsData | undefined;
    let translationData: IWordsData | undefined;

    if (useCurrentTranscription && !this.isTranslation && !gAPP_STORE.viewTranslate) {
      transcriptionData = this.currentWordsData;
    } else {
      if (record.transcriptionId) {
        transcriptionData = await BackendService.get(`text/${this.selectedTranscriptionId ?? record.transcriptionId}`);
      }
    }

    const historyTranscription = await this.getHistoryToExport(record.correlationId, false, 'automaticallyRecognized');
    const historyTranslation = await this.getHistoryToExport(record.correlationId, true, 'automaticallyTranslated');

    if (transcriptionData && transcriptionData.words) {
      transcriptionData = this.preprocessWords(transcriptionData, record.languageResolved);

      if (record.translationId !== null) {
        // получаем первый перевод (автоматический), запросив только первую строку из истории
        const autoTranslationRequestBody = {
          correlationId: record.correlationId,
          limit: 1,
          offset: 0,
          sortOrder: 'Asc',
        };
        const autoTranslationRow: IWordsData[] = await BackendService.post(
          'text/translate/history',
          JSON.stringify(autoTranslationRequestBody),
        );
        if (autoTranslationRow && autoTranslationRow.length === 1) {
          translationAutoData = autoTranslationRow[0];

          // если автоматический перевод был изменен, получаем актуальный перевод
          if (useCurrentTranscription && this.selectedTranslationId) {
            translationData = this.isTranslation
              ? this.currentWordsData
              : await BackendService.get(`text/translate/${this.selectedTranslationId}`);
          } else {
            if (translationAutoData?.id !== record.translationId) {
              translationData = await BackendService.get(`text/translate/${record.translationId}`);
            }
          }

          translationAutoData = this.preprocessWords(translationAutoData, record.translateLanguage);

          if (translationData && translationData.words) {
            translationData = this.preprocessWords(translationData, record.translateLanguage);
          }
        }
      }
    }

    return {
      transcription: transcriptionData,
      translationAuto: translationAutoData,
      translation: translationData,
      historyTranscription,
      historyTranslation,
    };
  }

  preprocessWords = (wordsData: IWordsData, wordsLanguage: string): IWordsData => {
    return wordsLanguage === ESpecialLanguageCode.EN ? correctIm(wordsData) : wordsData;
  };

  private _markFilteredWord(words: IWord[], filterWords: string[]) {
    if (filterWords.length === 0) return;
    words.forEach(word => {
      word.filtered = undefined;
      word.inFilterPhrase = undefined;
      const thisWord = word.text.toLowerCase();
      let filtered: IFiltered[] = [];
      filterWords.forEach(f => {
        if (!thisWord.includes(f)) return;
        const indices = getIndicesOf(f, thisWord, true);
        filtered = [...filtered, ...indices.map(i => ({ from: i, upto: i + f.length - 1 }))];
      });
      if (filtered.length > 0) {
        word.filtered = filtered;
      }
    });
  }

  private _markFilteredInPhrase(sentence: IPhrase, filterPhrase: IFilterPhrase[]) {
    if (filterPhrase.length === 0 || sentence.textLen === 0) return;
    let indToken = sentence.tokens.length - 1;
    while (indToken > 0) {
      const curEnd = sentence.tokens[indToken];
      const curEndText = curEnd.text.toLowerCase();
      // eslint-disable-next-line no-loop-func
      const possibleFilter = filterPhrase.filter(ff => {
        return curEndText.startsWith(ff.words.at(-1) ?? '') && ff.words.length <= indToken + 1;
      });
      if (possibleFilter.length === 0) {
        indToken--;
        continue;
      }
      // eslint-disable-next-line no-loop-func
      possibleFilter.forEach(filter => {
        const len = filter.words.length;
        const text = sentence.tokens
          .slice(indToken - len + 1, indToken + 1)
          .map(v => v.text.toLowerCase())
          .join(' ');
        if (!text.includes(filter.text)) return;
        for (let ind = indToken - len + 1, j = 0; ind < indToken + 1; ind++, j++) {
          sentence.tokens[ind].inFilterPhrase = filter;
          const filtered = sentence.tokens[ind].filtered ?? [];
          if (ind === indToken - len + 1) {
            //первое слово фильттра
            filtered.push({
              from: sentence.tokens[ind].text.length - filter.words[0].length,
              upto: sentence.tokens[ind].text.length - 1,
            });
          } else {
            //внутренние слова фильтра
            filtered.push({ from: 0, upto: filter.words[j].length - 1 });
          }
          sentence.tokens[ind].filtered = filtered;
        }
      });
      indToken--;
    }
  }

  private _mergeSelectedSubWords(words: IWord[]) {
    //схлопываем пересекающиеся выделения внутри слов
    words.forEach(w => {
      if (!w.filtered || w.filtered.length === 1) return;
      const parts = [...w.filtered].sort((a, b) => (a.from !== b.from ? a.from - b.from : a.upto - b.upto));

      const len = parts.length;
      let ind = 0;
      const merged: IFiltered[] = [];
      while (ind < len) {
        const d = { ...parts[ind] };
        ind++;
        while (ind < len && parts[ind].from <= d.upto) {
          if (d.upto < parts[ind].upto) {
            d.upto = parts[ind].upto;
          }
          ind++;
        }
        merged.push(d);
      }
      if (merged.length !== len) {
        w.filtered = merged;
      }
    });
  }

  markFilteredChangedPhrase(newPhraseWords: IWord[], editedPhrase: IPhrase) {
    const { filterWords, filterPhrase } = gAPP_STORE
      .getFilterStore()
      .getSeparatedFilter({ translation: this.isTranslation });
    if (filterWords.length === 0 && filterPhrase.length === 0) return;
    this._markFilteredWord(newPhraseWords, filterWords);
    this._markFilteredInPhrase(editedPhrase, filterPhrase);
    this._mergeSelectedSubWords(newPhraseWords);
  }

  markFilteredInWordData(wordsData: IWordsData | undefined): IAppliedFilter | undefined {
    if (!wordsData) return undefined;

    const filter = gAPP_STORE.getFilterStore().getSeparatedFilter({ translation: this.isTranslation });

    if (filter.filterWords.length === 0 && filter.filterPhrase.length === 0) {
      wordsData.words.forEach(channelWords =>
        channelWords.words.forEach(w => {
          w.filtered = undefined;
          w.inFilterPhrase = undefined;
        }),
      );

      return undefined;
    }

    wordsData.words.forEach(channelWords => {
      const words: IWord[] = channelWords.words;
      this._markFilteredWord(words, filter.filterWords);
      this._markFilteredInPhrase(wordsToСhannelText(words), filter.filterPhrase);
      this._mergeSelectedSubWords(words);
    });

    return filter;
  }

  get markers(): IMarkers {
    const wd = this.changedWordsData;
    const result: IMarkers = { words: [], phrases: [] };

    if (wd === undefined) return result;

    wd.words.forEach(channelWords => {
      let phraseWordIndex = 0;
      let phrase: IWord[] = [];

      channelWords.words.forEach(word => {
        if (word.filtered !== undefined) {
          if (!word.inFilterPhrase) {
            if (
              word.filtered.length === 1 &&
              word.filtered[0].from === 0 &&
              word.filtered[0].upto + 1 === word.text.length
            ) {
              result.words.push(word);
            } else {
              //делаем много кусочков
              const count = word.filtered.length;
              const dt = (word.end - word.begin) / count;
              word.filtered.forEach((p, ind) =>
                result.words.push({
                  ...word,
                  text: word.text.slice(p.from, p.upto + 1),
                  begin: word.begin + dt * ind,
                  end: word.begin + dt * (ind + 1),
                }),
              );
            }
          } else {
            if (phraseWordIndex < word.inFilterPhrase.words.length - 1) {
              phrase.push(word);
              phraseWordIndex++;
            } else {
              phrase.push(word);
              result.phrases.push([...phrase]);
              phrase = [];
              phraseWordIndex = 0;
            }
          }
        }
      });
    });

    return result;
  }
}
