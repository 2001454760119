import React from 'react';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { ArrowDownward, ArrowUpward } from '@material-ui/icons';
import ErrorIcon from '@material-ui/icons/Error';
import SettingsIcon from '@material-ui/icons/Settings';
import { observer, useObserver } from 'mobx-react';
import { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import pure from 'recompose/pure';
import { EDataGridStoreSortMode, IDataGridColBase } from '@uk';
import { useForceUpdate } from '@uk/common/hooks';
import LoadingPanel from '@uk/components/loading/LoadingPanel';
import ukColors from '@uk/ukColors';
import { Panel } from '../panel';
import ColumnResizer from './ColumnResizer';
import ColumnResizerLine from './ColumnResizerLine';
import styles from './dataGrid.module.scss';
import DataGridPagination from './DataGridPagination';
import DataGridSettings, { IColumnLayout } from './DataGridSettings';
import RowActions from './RowActions';
import { IDataGridProps } from './types';
import { getRandomIntFromZero } from '@uk/utils/intUtils';
import { CommonDialog } from '@uk';
import { CommonDialogUiStore } from '../commonDialog';
import { DataGridRow } from './DataGridRow';
import { DataGridCard } from './DataGridCard';
import { Typography } from '@material-ui/core';

const SIZE: 'small' | 'medium' = 'medium';

const CELL_PADDING_CX = 16;

const handleCheckboxOnClick = (event: any) => {
  event.stopPropagation();
};

const DataGrid: React.FC<IDataGridProps> = ({
  id,
  cols,
  gridStore,
  isMultiselect,
  checkIfSelected,
  hideHeader,
  fullWidth,
  fullHeight,
  noPaging,
  fixedRowHeight,
  noPageNumbers,
  rowAction,
  rowCardRenderer,
  onColumnActions,
  onRowDblClick,
  isNeedTooltipForContent,
  inscriptions,
}) => {
  const { t } = useTranslation();

  const settingsDlgUiStoreRef = useRef<CommonDialogUiStore>(new CommonDialogUiStore());

  const rootRef = useRef<HTMLDivElement | null>(null);
  const columnResizerLineRef = useRef<HTMLDivElement | null>(null);

  const forceUpdate = useForceUpdate();
  const checkedRows = useObserver(() => gridStore.checkedRows);
  const gridSettingsStore = gridStore.gridSettingsStore;

  const getGlobalRowIndex = useCallback(
    (rowIndex: number) => gridStore.currentPage * gridStore.rowsOnPage + rowIndex,
    [gridStore.currentPage, gridStore.rowsOnPage]
  );
  const handleRowClick = useCallback(
    (row: any, rowIndex: number) => {
      const globalRowIndex = getGlobalRowIndex(rowIndex);

      let rowIndexes: number[] = [];
      if (isMultiselect) {
        if (gridStore.selectedRowIndexes) {
          if (gridStore.selectedRowIndexes.includes(globalRowIndex)) {
            rowIndexes = gridStore.selectedRowIndexes.filter((i: any) => i !== globalRowIndex);
          } else {
            rowIndexes = [...gridStore.selectedRowIndexes, globalRowIndex];
          }
        }
      } else {
        rowIndexes = [globalRowIndex];
      }
      if (rowIndexes.toString() !== gridStore.selectedRowIndexes.toString()) {
        gridStore.setSelectedRowIndexes(rowIndexes);
      }

      if (gridStore.selectCb) {
        // инверсия потому что в selectedRows всегда предыдущее состояние
        gridStore.selectCb(rowIndex, !gridStore.selectedRowIndexes.includes(rowIndex), row);
      }
    },
    [getGlobalRowIndex, gridStore, isMultiselect]
  );

  const handleRowDblClick = useCallback(
    (row: any, rowIndex: number) => {
      if (onRowDblClick) {
        onRowDblClick(row);
      }
    },
    [onRowDblClick]
  );

  const handleCheckRow = useCallback(
    (row: any, rowIndex: number, e?: any) => {
      const globalRowIndex = getGlobalRowIndex(rowIndex);

      if (checkedRows) {
        let rowIndexes: number[] = [];
        if (checkedRows.includes(globalRowIndex)) {
          rowIndexes = checkedRows.filter((i: any) => i !== globalRowIndex);
        } else {
          // multi-check with Shift Key
          if (e && e.nativeEvent.shiftKey && checkedRows.length > 0) {
            const lastChecked = checkedRows[checkedRows.length - 1];

            if (globalRowIndex > lastChecked) {
              const delta = globalRowIndex - lastChecked;
              rowIndexes = [
                ...checkedRows,
                ...Array.from({ length: delta }, (v: number = lastChecked + 1, i) => i + v),
              ];
            } else if (globalRowIndex < lastChecked) {
              const delta = lastChecked - globalRowIndex;
              rowIndexes = [
                ...checkedRows,
                ...Array.from({ length: delta }, (v: number = globalRowIndex, i) => i + v),
              ];
            }
          } else {
            rowIndexes = [...checkedRows, globalRowIndex];
          }
        }
        gridStore.setCheckedRows(rowIndexes);

        if (gridStore.checkCb) {
          gridStore.checkCb(rowIndex, rowIndexes.includes(rowIndex), row);
        }
      }
    },
    [checkedRows, getGlobalRowIndex, gridStore]
  );

  const handleCheckAll = useCallback(() => {
    if (gridStore.checkedRows.length === 0) {
      gridStore.setCheckedRows(
        Array.from(Array(gridStore.data.length).keys()).map((rowIndex) =>
          getGlobalRowIndex(rowIndex)
        )
      );
    } else {
      gridStore.setCheckedRows([]);
    }
  }, [getGlobalRowIndex, gridStore]);

  const getColName = (col: IDataGridColBase, colLayout: IColumnLayout) => `${t(col.name)}`;

  const getColWidth = (col: IDataGridColBase, colLayout: IColumnLayout, colName: string) => {
    const colHeaderTextSize = t(colName).length * 8 + CELL_PADDING_CX * 2;
    return colLayout.width
      ? `${Math.max(colHeaderTextSize, colLayout.width)}px`
      : col.width
      ? `${Math.max(colHeaderTextSize, col.width)}px`
      : undefined;
  };

  const needSettings =
    gridSettingsStore.cols.filter((col) => !col.hideInGrid && !col.hasCheck).length > 1;

  const defineClick = useCallback(
    (event: any, row: any, rowIndex: number) => {
      switch (event.detail) {
        case 1:
          handleRowClick(row, rowIndex);
          break;
        case 2:
          handleRowDblClick(row, rowIndex);
          break;
      }
    },
    [handleRowClick, handleRowDblClick]
  );

  // Ошибка загрузки данных
  if (gridStore.error) {
    return (
      <Panel>
        <Box display="flex" alignItems="start" flexGrow={1}>
          <Box flexGrow={1}>
            <Box p={3} display="flex" alignItems="center">
              <ErrorIcon style={{ fontSize: '2em', color: ukColors.red }} />
              <Box pl={2} color={ukColors.red}>
                {gridStore.error}
              </Box>
            </Box>
          </Box>
        </Box>
        {!noPaging && <DataGridPagination gridStore={gridStore} noPageNumbers={noPageNumbers} />}
      </Panel>
    );
  }

  // Загрузка данных
  if (gridStore.isPending) {
    return (
      <Panel>
        <Box display="flex" alignItems="start" flexGrow={1}>
          <Box flexGrow={1}>
            <LoadingPanel />
          </Box>
        </Box>
        {!noPaging && <DataGridPagination gridStore={gridStore} noPageNumbers={noPageNumbers} />}
      </Panel>
    );
  }

  // Пустые данные
  if ((gridStore.data || []).length === 0) {
    return (
      <Panel>
        <Box display="flex" alignItems="start" flexGrow={1}>
          <Box flexGrow={1}>
            <Box p={3} display="flex" justifyContent="center">
              <Typography variant="body1" style={{ color: ukColors.lightGrey }}>
                {`${inscriptions?.noData ?? t('noData')}`}
              </Typography>
            </Box>
          </Box>
        </Box>
        {!noPaging && <DataGridPagination gridStore={gridStore} noPageNumbers={noPageNumbers} />}
      </Panel>
    );
  }

  // Если определен рендерер для карточки ряда, то рисуем карточки
  if (rowCardRenderer && gridSettingsStore.showCards) {
    return (
      <Panel refObject={rootRef}>
        <Panel p={2} verticalScroll bgcolor={ukColors.lightLightGrey}>
          <Box display="flex" flexWrap="wrap">
            {!gridStore.isPending &&
              gridStore.data &&
              gridStore.data.map((row, index) => {
                return (
                  <DataGridCard
                    key={index}
                    gridStore={gridStore}
                    row={row}
                    rowIndex={index}
                    rowCardRenderer={rowCardRenderer}
                    onClick={defineClick}></DataGridCard>
                );
              })}
          </Box>
        </Panel>
        {!noPaging && <DataGridPagination gridStore={gridStore} noPageNumbers={noPageNumbers} />}
      </Panel>
    );
  }

  return (
    <>
      <Panel refObject={rootRef} zIndex={100}>
        <Panel verticalScroll={!fullHeight} position="relative">
          <Box flexGrow={1} style={{ overflowX: 'auto' }}>
            <Box style={{ width: fullWidth ? '100%' : 0, maxWidth: fullWidth ? '100%' : 0 }}>
              <Table
                data-autotest={id || getRandomIntFromZero(999999)}
                stickyHeader
                aria-label="sticky table"
                size={SIZE}
                style={{
                  width: fullWidth ? '100%' : 'auto',
                  tableLayout: fullWidth ? 'fixed' : 'auto',
                }}>
                {!hideHeader && (
                  <TableHead>
                    <TableRow className={styles.headRow}>
                      {needSettings && (
                        <>
                          <RowActions
                            className={styles.headCell}
                            size={SIZE}
                            icon={<SettingsIcon />}
                            autotestId={id + '_settingsBtn'}
                            isAlwaysVisible
                            onClick={() => settingsDlgUiStoreRef.current.setOpen(true)}
                          />
                        </>
                      )}

                      {gridSettingsStore.colsWithLayout.map((colLayout, index) => {
                        const col = colLayout.column;
                        const colName = getColName(col, colLayout);
                        const colWidth = getColWidth(col, colLayout, colName);

                        return (
                          <TableCell
                            data-autotest={'tableCell-' + index}
                            width={colWidth}
                            key={'tableCell-' + index}
                            align={col.align}
                            className={styles.headCell}>
                            <div
                              className={styles.cellContent}
                              style={{
                                justifyContent: col.align,
                                width: colWidth,
                              }}>
                              {col.hasCheck && !col.noAllChecked ? (
                                <Box pl={1}>
                                  <Checkbox
                                    color="primary"
                                    size={SIZE}
                                    style={{ padding: 0 }}
                                    indeterminate={
                                      gridStore.data.length !== 0 &&
                                      checkedRows.length > 0 &&
                                      checkedRows.length < gridStore.data.length
                                    }
                                    checked={
                                      gridStore.data.length !== 0 &&
                                      checkedRows.length >= gridStore.data.length
                                    }
                                    onClick={handleCheckboxOnClick}
                                    onChange={() => handleCheckAll()}
                                  />
                                </Box>
                              ) : !col.noSort ? (
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => {
                                    gridSettingsStore.setSortedColumn(colLayout);
                                    gridStore.setSortedColumn(colLayout);
                                    gridStore.reload();
                                  }}>
                                  {colLayout.sort === EDataGridStoreSortMode.ASC && (
                                    <ArrowDownward
                                      style={{ fontSize: '1.3em', color: ukColors.primary }}
                                    />
                                  )}
                                  {colLayout.sort === EDataGridStoreSortMode.DSC && (
                                    <ArrowUpward
                                      style={{ fontSize: '1.3em', color: ukColors.primary }}
                                    />
                                  )}
                                  <Box pl={1}>{colName}</Box>
                                </Box>
                              ) : (
                                <Box>{colName}</Box>
                              )}

                              {!fullWidth && (
                                <ColumnResizer
                                  colLayout={colLayout}
                                  rootRef={rootRef}
                                  columnResizeLineRef={columnResizerLineRef}
                                  updateLayout={() => {
                                    forceUpdate();
                                    gridSettingsStore.saveToLocalStorage();
                                  }}
                                />
                              )}
                            </div>
                          </TableCell>
                        );
                      })}

                      {rowAction && (
                        <TableCell
                          data-autotest={'tableCell-act'}
                          width={'50px'}
                          key={'tableCell-act'}
                          align={'right'}
                          className={styles.headCell}>
                          <div
                            className={styles.cellContent}
                            style={{
                              justifyContent: 'left',
                              width: '50px',
                            }}></div>
                        </TableCell>
                      )}
                    </TableRow>
                  </TableHead>
                )}

                <TableBody>
                  {!gridStore.isPending &&
                    gridStore.data &&
                    gridStore.data.map((row, rowIndex) => (
                      <DataGridRow
                        key={rowIndex}
                        row={row}
                        rowIndex={rowIndex}
                        SIZE={SIZE}
                        gridStore={gridStore}
                        fixedRowHeight={fixedRowHeight}
                        onClick={defineClick}
                        handleCheckRow={handleCheckRow}
                        rowAction={rowAction}
                        isNeedTooltipForContent={isNeedTooltipForContent}
                      />
                    ))}
                </TableBody>
              </Table>
            </Box>
          </Box>

          {!noPaging && <DataGridPagination gridStore={gridStore} noPageNumbers={noPageNumbers} />}
          <ColumnResizerLine ref={columnResizerLineRef} />
        </Panel>
      </Panel>

      <CommonDialog
        title={`${inscriptions?.settingsLabel ?? t('dataGridSettings')}`}
        autotestId={`DataGrid_Settings_${id}`}
        uiStore={settingsDlgUiStoreRef.current}
        onClose={() => settingsDlgUiStoreRef.current.setOpen(false)}
        contentComponent={() => (
          <DataGridSettings
            dataGridSettingsStore={gridSettingsStore}
            resetLabel={inscriptions?.settingsResetLabel}
          />
        )}
        okLabel={`${inscriptions?.settingsOkLabel ?? t('close')}`}
        noCancel
        bodyScroll
        bodyProps={{ minHeight: 400, minWidth: 400 }}
      />
    </>
  );
};

export default pure(observer(DataGrid));
