import { DataGridStore, IDataGridStoreData } from '@uk';
import { makeAutoObservable, observable, runInAction } from 'mobx';

import { BackendService } from 'services';

import { toast } from 'react-toastify';

import i18n from 'i18next';

import { IStoreWithLoader } from '../settings/types';

import { templatesGridColumns } from './templates-grid-columns';

import { ITemplate } from './i-template';

import { AbortControllerService } from '@/services/abort-controller-service';
import { BackendError } from '@/services/types';
import { checkIfAborted } from '@/utils/abortUtils';

export class TemplatesStore implements IStoreWithLoader {
  data: { templates: ITemplate[] } = { templates: [] };
  searchQuery = '';

  gridStore: DataGridStore<ITemplate>;
  gridCols = templatesGridColumns;
  abortControllerService = new AbortControllerService();
  dataProviderLoading = { state: false };

  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });

    const dataProvider = async (): Promise<IDataGridStoreData<ITemplate>> => {
      await this.getAllImpl();

      return { data: this.data.templates, total: this.data.templates.length, hasNextPage: false };
    };

    this.gridStore = new DataGridStore<ITemplate>('TemplateSettings', this.gridCols, { dataProvider: dataProvider });
  }
  isLoading(): boolean {
    return this.dataProviderLoading.state;
  }

  /**
   * Get all templates
   */
  getAll = async () => {
    await this.gridStore.reload();
  };

  private getAllImpl = async () => {
    const localCopy = observable({ state: true });

    try {
      const abortController = this.abortControllerService.control('getAllImpl');
      const networkProps = { sendOptions: { abort: abortController } };
      this.dataProviderLoading = localCopy;
      const data = await BackendService.get('template/all', networkProps?.sendOptions);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      runInAction(() => (this.data.templates = data.sort((a: any, b: any) => (a.id === b.id ? 0 : a.id - b.id))));
    } catch (error) {
      if (checkIfAborted(error)) return;

      this.data.templates = [];
      if (error instanceof BackendError && error.status === 401) {
        toast.error(i18n.t(error.statusMessage));
      } else {
        toast.error(i18n.t('BadRequest'));
        throw error;
      }
    } finally {
      localCopy.state = false;
      if (this.dataProviderLoading === localCopy) {
        this.abortControllerService.removeControl('getAllImpl');
      }
    }
  };

  /**
   * Create new template
   */
  create = async (template: ITemplate) => {
    try {
      const newTemplateData = {
        fileName: template.fileName,
        templateName: template.templateName,
        template_b64: btoa(template.template),
      };

      await BackendService.put('template/add', JSON.stringify(newTemplateData));
      this.gridStore.reload();
    } catch (error) {
      toast.error(i18n.t('BadRequest'));
      throw error;
    }
  };

  /**
   * Update template
   */
  update = async (template: ITemplate) => {
    try {
      await BackendService.put('template', JSON.stringify(template));
      this.gridStore.reload();
    } catch (error) {
      toast.error(i18n.t('BadRequest'));
      throw error;
    }
  };

  /**
   * Delete template
   */
  delete = async (template: ITemplate) => {
    try {
      await BackendService.delete(`template/${template.id.toString()}`);
      this.gridStore.reload();
    } catch (error) {
      toast.error(i18n.t('BadRequest'));
      throw error;
    }
  };
}
