import { ExportFileExtension } from '@/types/common';

export const LOCAL_STORAGE_USER_KEY = 'userSession';

// app-store for web-config
export const DEFAULT_MAX_FILES_TO_UPLOAD = 10;
export const DEFAULT_RECORD_EXTENSIONS = '.wav';
export const DEFAULT_AVAILABLE_LOCALES = 'en';
export const DEFAULT_MAX_SPEAKER_COUNT = 5;
export const DEFAULT_MAX_HOTLIST_COUNT = 5;
export const DEFAULT_SEGMANTATION_ALGORITHM = 'XVECTOR_V2';
export const DEFAULT_TEMPLATE_MAX_NAME = 100;
export const DEFAULT_TEMPLATE_MAX_SIZE = 10 * 1024 * 1024;

export const MAX_FILES_IN_DATABASE = 100000;
export const NUMBER_FIELD_VALUES = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace', 'Delete', 'Tab'];
export const MAX_DICTOR_NAME_LENGTH = 100;
export const MAX_DICTOR_NAME_LENGTH_CHIP = 20;
export const MAX_DICTOR_NAME_LENGTH_POPPER = 100;
export const MOVE_THROUGH_TEXT_KEYS = ['Backspace', 'Delete', 'End', 'Home', 'ArrowLeft', 'ArrowRight'];

export const Z_INDEX_PLAYER = 1000;
export const Z_INDEX_POPPER = 1100;
export const Z_INDEX_COMMON_DIALOG = 1300;

export const TIMEOUT_DICTOR_STAT = 1000;

export const exportFileExtensions: ExportFileExtension[] = ['docx', 'pdf'];
export const exportFileExtensionsCsvXlsx: ExportFileExtension[] = ['.csv', '.xlsx'];

export enum EExportFormats {
  csv = '.csv',
  xlsx = '.xlsx',
}

export const eventLogs = {
  MAX_ACTIONS: 10,
};

export const dialogRecordsGroups = {
  MAX_GROUPS_COUNT: 10,
};

export const userSettingsRestrictions = {
  MIN_NAME_LENGTH: 2,
  MAX_NAME_LENGTH: 70,
  MIN_LOGIN_LENGTH: 5,
  MAX_LOGIN_LENGTH: 30,
  MIN_PASSWORD_LENGTH: 4,
  MAX_PASSWORD_LENGTH: 30,
  MAX_GROUPS_COUNT: 10,
};

export const groupSettingsRestrictions = {
  MIN_NAME_LENGTH: 1,
  MAX_NAME_LENGTH: 40,
  MAX_DESCRIPTION_LENGTH: 500,
};

export const topicsRestrictions = {
  MAX_WORDS_COUNT: 50,
  MAX_WORD_LENGTH: 100,
  MAX_TOPIC_NAME: 40,
  SEPARATOR: ',',
};

export const templeteRestrictions = {
  MIN_NAME_LENGTH: 1,
  MAX_NAME_LENGTH: 100,
};

export const EventLogReportFileRestrictions = {
  MAX_FILENAME_LENGTH: 100,
  MAX_DESCRIPTION_LENGTH: 500,
};

export const MAX_INPUT_EXPORT_FILENAME_LENGTH = 100;

export const dialogUploadRecordsRestrictions = {
  MAX_VISIBLE_FILENAME_LENGTH: 60,
  MAX_VISIBLE_HOTLIST_LENGTH: 60,
  MAX_INPUT_TEXT_LENGTH: 1200,
  MAX_GROUPS_COUNT: 10,
  MAX_LABEL_COUNT: 10,
  MAX_TOPICS_COUNT: 10,
  MAX_WORDS_COUNT: 20,
  MIN_LETTERS_COUNT: 1,
  MAX_LETTERS_COUNT: 100,
};

export const SHARDSIZE = 400 * 1024 * 1024; //Split units are divided into 400MB

export const dialogLabelsRestrictions = {
  MAX_COMMENTS_LENGTH: 100,
};

export const dialogDataSourceRestrictions = {
  MAX_NAME_LENGTH: 100,
  MIN_NAME_LENGTH: 1,
  MAX_CISCO_NAME_LENGTH: 200,
  MAX_SPACE_ID_LENGTH: 255,
  MAX_GROUPS_COUNT: 10,
  MAX_USERS_COUNT: 10,
  MIN_USERS_COUNT: 1,
  MIN_GROUPS_COUNT: 1,
};
